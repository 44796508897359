import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import 'antd/dist/reset.css';
import './App.css';
import PricingCalculator from './containers/PricingCalculator';
import DocumentCreator from './containers/DocumentCreator';
import TemplateViewer from './containers/TemplateViewer';
import Home from './containers/Home';
import MoveInCalculatorApp from './apps/MoveInCalculatorApp';
import MoveOutCalculatorApp from './apps/MoveOutCalculatorApp';
import RentSplitCalculatorApp from './apps/RentSplitCalculatorApp';
import NetOperatingIncomeCalculatorApp from './apps/NetOperatingIncomeCalculatorApp';
import CapRateCalculatorApp from './apps/CapRateCalculatorApp';
import RoiCalculatorApp from './apps/RoiCalculatorApp';
import withTracker from './components/withTracker.js/withTracker';

function App() {
  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/"
          component={withTracker(Home, {page_title: 'Home'})}
        />
        <Route
          path="/move-in-calculator"
          component={withTracker(MoveInCalculatorApp, {
            page_title: 'Move-in Calculator'
          })}
        />
        <Route
          path="/move-out-calculator"
          component={withTracker(MoveOutCalculatorApp, {
            page_title: 'Move-out Calculator'
          })}
        />
        <Route
          path="/net-operating-income-calculator"
          component={withTracker(NetOperatingIncomeCalculatorApp, {
            page_title: 'NOI Caculator'
          })}
        />
        <Route
          path="/cap-rate-calculator"
          component={withTracker(CapRateCalculatorApp, {
            page_title: 'Cap-Rate Calculator'
          })}
        />
        <Route
          path="/roi-calculator"
          component={withTracker(RoiCalculatorApp, {
            page_title: 'ROI Calculator'
          })}
        />
        <Route
          path="/pricing-calculator"
          component={withTracker(PricingCalculator, {
            page_title: 'Pricing Calculator'
          })}
        />
        <Route
          path="/rent-split-calculator"
          component={withTracker(RentSplitCalculatorApp, {
            page_title: 'Rent-split Calculator'
          })}
        />
        <Route
          path="/document/create"
          component={withTracker(DocumentCreator, {
            page_title: 'Document Creator'
          })}
        />
        <Route
          path="/document/template"
          component={withTracker(TemplateViewer, {
            page_title: 'Template Viewer'
          })}
        />
      </Switch>
    </Router>
  );
}

export default App;
