import {Magic, RPCError, RPCErrorCode} from 'magic-sdk';
import {OAuthExtension} from '@magic-ext/oauth';

const {REACT_APP_PK_KEY} = process.env;
const magic = new Magic(REACT_APP_PK_KEY, {
  extensions: [new OAuthExtension()]
});

export const checkUser = async () => {
  const isLoggedIn = await magic.user.isLoggedIn();
  if (isLoggedIn) {
    const user = await magic.user.getMetadata();
    return {isLoggedIn: true, email: user.email};
  }
  return {isLoggedIn: false};
};

export const loginUser = async (email, showUI = true) => {
  // console.log('ƒ loginUser', email, REACT_APP_LOGIN_REDIRECT);

  try {
    // await magic.auth.loginWithMagicLink({
    //   email,
    //   redirectURI: REACT_APP_LOGIN_REDIRECT,
    //   showUI
    // });

    return await magic.auth.loginWithEmailOTP({email, showUI});
  } catch (err) {
    if (err instanceof RPCError) {
      switch (err.code) {
        case RPCErrorCode.MagicLinkFailedVerification:
          throw Error('Verification failed');
        case RPCErrorCode.MagicLinkExpired:
          throw Error('Magic Link expired');
        case RPCErrorCode.MagicLinkRateLimited:
          throw Error('Too many requests');
        case RPCErrorCode.UserAlreadyLoggedIn:
          throw Error('User is already logged in');
        default:
          throw err;
      }
    }
  }
};

export const loginGoogleUser = async () => {
  try {
    await magic.oauth.getRedirectResult();
  } catch (err) {
    throw err;
  }
};

export const loginWithCredential = async (token) => {
  try {
    await magic.auth.loginWithCredential(token);
  } catch (err) {
    throw err;
  }
};

export const logoutUser = async () => {
  return await magic.user.logout();
};

export const getToken = async () => {
  try {
    return await magic.user.getIdToken();
  } catch (err) {
    throw err;
  }
};
