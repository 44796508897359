import React, {useState, useEffect} from 'react';
import {Form, Input, Space, Slider, InputNumber, Row, Col} from 'antd';
import {roiCalculator} from '../../library/helpers/calculators';
import {Typography} from 'antd';
import RoiCalculatorResult from '../RoiCalculatorResult/RoiCalculatorResult';
const {Title, Text} = Typography;

function percentFormatter(value) {
  return `${value}%`;
}

// function currencyFormatter(value) {
//   var formatter = new Intl.NumberFormat('en-US', {
//     style: 'currency',
//     currency: 'USD'
//   });
//   return formatter.format(value);
// }

const vacancyMarks = {
  0: '0%',
  5: '5%',
  10: '10%',
  15: '15%'
};

const loanMarks = {
  0: '0',
  5: '5',
  10: '10',
  15: '15',
  30: '30',
  40: '40'
};

const CalculatorForm = (props) => {
  const [calculatorVals, setCalculatorVals] = useState({
    inputs: {
      vacancyRate: 7,
      rentIncome: 2000,
      loanTerm: 15,
      propertyValue: 200000,
      interestRate: 3.75,
      downPayment: 40000,
      closingCosts: 4000,
      improvements: 0,
      otherIncome: 0,
      propertyTaxes: 0,
      insurance: 0,
      utilities: 0,
      repairs: 0,
      managementFees: 0,
      otherExpense: 0
    },
    netOperatingIncome: 0,
    annualCashFlow: 0,
    grossRentMultiplier: 0,
    debtServiceCoverageRatio: 0,
    capRate: 0,
    cashOnCashRate: 0
  });

  useEffect(() => {
    // console.log('ƒ useEffect on calculatorVals', calculatorVals);
    if (calculatorVals.inputs) {
      let calculatedCosts = roiCalculator(calculatorVals.inputs);

      setCalculatorVals((prevCalculatorVals) => {
        return {
          ...prevCalculatorVals,
          netOperatingIncome: calculatedCosts.netOperatingIncome,
          capRate: calculatedCosts.capRate,
          cashOnCashReturn: calculatedCosts.cashOnCashReturn,
          grossRentMultiplier: calculatedCosts.grossRentMultiplier,
          annualCashFlow: calculatedCosts.annualCashFlow,
          debtServiceCoverageRatio: calculatedCosts.debtServiceCoverageRatio
        };
      });
    }
  }, [calculatorVals.inputs]);

  return (
    <Form
      layout="vertical"
      name="RoiCalculator"
      initialValues={{
        rentIncome: calculatorVals.inputs.rentIncome,
        propertyValue: calculatorVals.inputs.propertyValue,
        interestRate: calculatorVals.inputs.interestRate,
        downPayment: calculatorVals.inputs.downPayment,
        closingCosts: calculatorVals.inputs.closingCosts
      }}
      onValuesChange={(changedValues) => {
        const key = Object.keys(changedValues)[0];
        const value = Object.values(changedValues)[0];

        changedValues[key] = value ? parseFloat(value) : 0;
        const prevCalulatorVals = calculatorVals;
        setCalculatorVals({
          ...prevCalulatorVals,
          inputs: {...prevCalulatorVals.inputs, ...changedValues}
        });
      }}
    >
      <Title level={3}>Property</Title>
      <Space size={14}>
        <Form.Item label="Property Value" name="propertyValue">
          <Input prefix="$" />
        </Form.Item>
        <Form.Item label="Down Payment" name="downPayment">
          <Input prefix="$" />
        </Form.Item>
      </Space>
      <Space size={14}>
        <Form.Item label="Closing Costs" name="closingCosts">
          <Input prefix="$" />
        </Form.Item>
        <Form.Item label="Improvements" name="improvements">
          <Input prefix="$" />
        </Form.Item>
      </Space>
      <Form.Item>
        <Text>Interest Rate (annual)</Text>
        <Row gutter={[18, 18]}>
          <Col span={16}>
            <Slider
              label="APR"
              name="interestRate"
              tipFormatter={percentFormatter}
              defaultValue={calculatorVals.inputs.interestRate}
              value={calculatorVals.inputs.interestRate}
              marks={vacancyMarks}
              dots
              min={0}
              max={15}
              step={0.025}
              onChange={(value) => {
                const prevCalculatorVals = {...calculatorVals};
                setCalculatorVals({
                  inputs: {
                    ...prevCalculatorVals.inputs,
                    interestRate: value
                  }
                });
              }}
            />
          </Col>
          <Col span={4}>
            <InputNumber
              defaultValue={calculatorVals.inputs.interestRate}
              value={calculatorVals.inputs.interestRate}
              onChange={(value) => {
                const prevCalculatorVals = {...calculatorVals};
                setCalculatorVals({
                  inputs: {
                    ...prevCalculatorVals.inputs,
                    interestRate: value
                  }
                });
              }}
            />
          </Col>
        </Row>
      </Form.Item>
      <Form.Item>
        <Text>Loan Term (years)</Text>
        <Slider
          label="Loan Term"
          name="loanTerm"
          defaultValue={calculatorVals.inputs.loanTerm}
          marks={loanMarks}
          min={0}
          max={40}
          step={1}
          onChange={(value) => {
            const prevCalculatorVals = {...calculatorVals};
            setCalculatorVals({
              inputs: {
                ...prevCalculatorVals.inputs,
                loanTerm: value
              }
            });
          }}
        />
      </Form.Item>
      <Title level={3}>
        Income <span style={{fontSize: '14px'}}>(monthly)</span>
      </Title>
      <Space size={14}>
        <Form.Item label="Rent" name="rentIncome">
          <Input prefix="$" />
        </Form.Item>
        <Form.Item label="Other Income" name="otherIncome">
          <Input prefix="$" />
        </Form.Item>
      </Space>
      <Form.Item>
        <Text>Vacancy Rate</Text>
        <Slider
          label="Vacancy Rate"
          name="vacancyRate"
          tipFormatter={percentFormatter}
          defaultValue={calculatorVals.inputs.vacancyRate}
          dots
          marks={vacancyMarks}
          min={0}
          max={15}
          step={1}
          onChange={(value) => {
            const prevCalculatorVals = {...calculatorVals};
            setCalculatorVals({
              inputs: {
                ...prevCalculatorVals.inputs,
                vacancyRate: value
              }
            });
          }}
        />
      </Form.Item>
      <Title level={3}>
        Expenses <span style={{fontSize: '14px'}}>(annual)</span>
      </Title>
      <Space size={14}>
        <Form.Item label="Property Taxes" name="propertyTaxes">
          <Input prefix="$" />
        </Form.Item>
        <Form.Item label="Insurance" name="insurance">
          <Input prefix="$" />
        </Form.Item>
      </Space>
      <Space size={14}>
        <Form.Item label="Utilities" name="utilities">
          <Input prefix="$" />
        </Form.Item>
        <Form.Item label="Repairs and Maint." name="repairs">
          <Input prefix="$" />
        </Form.Item>
      </Space>
      <Space size={14}>
        <Form.Item label="Management Fees" name="managementFees">
          <Input prefix="$" />
        </Form.Item>
        <Form.Item label="Other Expenses" name="otherExpense">
          <Input prefix="$" />
        </Form.Item>
      </Space>
      <RoiCalculatorResult {...calculatorVals} title="ROI" />
    </Form>
  );
};
export default CalculatorForm;
