import React from 'react';
import {createRoot} from 'react-dom/client';
import {ConfigProvider} from 'antd';
import './index.css';
import App from './App';
import {FirebaseAppProvider} from 'reactfire';
// import reportWebVitals from './reportWebVitals';
import {firebaseConfig} from './library/firebase/firebase';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-K74RBL5'
};

TagManager.initialize(tagManagerArgs);

const container = document.getElementById('calculators');
const root = createRoot(container);
root.render(
  <FirebaseAppProvider firebaseConfig={firebaseConfig}>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#541388',
          colorLink: '#541388',
          colorLinkHover: '#cb47b8'
        }
      }}
    >
      <App />
    </ConfigProvider>
  </FirebaseAppProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
