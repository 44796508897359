import axios from 'axios';
const source = axios.CancelToken.source();

export default async function createLead(data) {
  //console.log('[freshsalesLead.js] ƒ createLead', data);
  try {
    const options = {
      method: 'post',
      url: `${process.env.REACT_APP_ADMIN_API}/freshsales`,
      headers: {
        'Content-Type': 'application/json',
        'X-Api-Key': process.env.REACT_APP_ADMIN_API_KEY
      },
      data: {
        last_name: data.email.split('@')[0],
        email: data.email,
        lead_source_id: 9000322784,
        custom_field: {
          cf_number_of_units: data.unitCount,
          cf_property_types: data.propertyType
        }
      }
    };
    //console.log(options);
    const results = await axios(options);
    return results;
  } catch (err) {
    if (axios.isCancel(err)) {
      console.log(err.message);
    } else if (err.response) {
      // client received an error response (5xx, 4xx)
      console.error(err.response);
      return err.response;
    } else if (err.request) {
      // client never received a response, or request never left
      console.error(err.request);
      return err.request;
    } else console.error(err);
  }
  source.cancel('Request canceled.');
}
