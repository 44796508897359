import {ResultWrapper} from '../components.styles';
import {StyledNumber, ErrorText} from '../components.styles';
import {Typography, Row, Col} from 'antd';
import numeral from 'numeral';
import {useSpring} from 'react-spring';
const {Text} = Typography;

const RoiCalculatorResult = (props) => {
  //console.log('ƒ RoiCalculatorResult', props);
  const {
    netOperatingIncome,
    capRate,
    grossRentMultiplier,
    cashOnCashReturn,
    annualCashFlow,
    debtServiceCoverageRatio
  } = props;
  const noiSpring = useSpring({
    from: {num: 0},
    to: {num: netOperatingIncome}
  });
  const capRateSpring = useSpring({
    from: {num: 0},
    to: {num: capRate}
  });

  const cocSpring = useSpring({
    from: {num: 0},
    to: {num: cashOnCashReturn}
  });
  const grmSpring = useSpring({
    from: {num: 0},
    to: {num: grossRentMultiplier}
  });
  const acfSpring = useSpring({
    from: {num: 0},
    to: {num: annualCashFlow}
  });
  const dscrSpring = useSpring({
    from: {num: 0},
    to: {num: debtServiceCoverageRatio}
  });
  return (
    <>
      <ResultWrapper>
        <Row gutter={[12, 18]}>
          <Col span={12}>
            <Text style={{color: '#122c34', fontWeight: '700'}}>
              Net Income (NOI)
            </Text>
            <br />
            {noiSpring < 0 ? (
              <ErrorText>Error. Check your settings. </ErrorText>
            ) : (
              ''
            )}
            <StyledNumber>
              {noiSpring.num.to((v) => numeral(v).format('$0,0.00'))}
            </StyledNumber>
          </Col>
          <Col span={12}>
            <Text style={{color: '#122c34', fontWeight: '700'}}>
              Annual Cash Flow
            </Text>
            <br />
            {acfSpring < 0 ? (
              <ErrorText>Error. Check your settings. </ErrorText>
            ) : (
              ''
            )}
            <StyledNumber>
              {acfSpring.num.to((v) => numeral(v).format('$0,0.00'))}
            </StyledNumber>
          </Col>
        </Row>
        <Row gutter={[12, 18]}>
          <Col span={12}>
            <Text style={{color: '#122c34', fontWeight: '700'}}>
              Debt Service Ratio
            </Text>
            <br />
            {dscrSpring < 0 ? (
              <ErrorText>Error. Check your settings. </ErrorText>
            ) : (
              ''
            )}
            <StyledNumber>
              {dscrSpring.num.to((v) => numeral(v).format('0.00'))}
            </StyledNumber>
          </Col>
          <Col span={12}>
            <Text style={{color: '#122c34', fontWeight: '700'}}>GRM</Text>
            <br />
            {grmSpring < 0 ? (
              <ErrorText>Error. Check your settings. </ErrorText>
            ) : (
              ''
            )}
            <StyledNumber>
              {grmSpring.num.to((v) => numeral(v).format('0.00'))}
            </StyledNumber>
          </Col>
        </Row>
        <Row gutter={[12, 18]}>
          <Col span={12}>
            <Text style={{color: '#122c34', fontWeight: '700'}}>
              Cash/Cash Return
            </Text>
            <br />
            {cocSpring < 0 ? (
              <ErrorText>Error. Check your settings. </ErrorText>
            ) : (
              ''
            )}
            <StyledNumber>
              {cocSpring.num.to((v) => numeral(v).format('0.00%'))}
            </StyledNumber>
          </Col>
          <Col span={12}>
            <Text style={{color: '#122c34', fontWeight: '700'}}>Cap Rate</Text>
            <br />
            {capRateSpring < 0 ? (
              <ErrorText>Error. Check your settings. </ErrorText>
            ) : (
              ''
            )}
            <StyledNumber>
              {capRateSpring.num.to((v) => numeral(v).format('0.00%'))}
            </StyledNumber>
          </Col>
        </Row>
      </ResultWrapper>
    </>
  );
};
export default RoiCalculatorResult;
