import React, {Component} from 'react';
import {logEvent} from 'firebase/analytics';
import {analytics} from '../../library/firebase/firebase';

export default function withTracker(WrappedComponent, options = {}) {
  const trackPage = (page) => {
    logEvent(analytics, 'page_view', {
      page_location: window.location.href,
      page_path: page,
      ...options
    });
  };

  const HOC = class extends Component {
    componentDidMount() {
      const {
        location: {pathname: page}
      } = this.props;
      trackPage(page);
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
      const {
        location: {pathname: currentPage}
      } = this.props;
      const nextPage = nextProps.location.pathname;

      if (currentPage !== nextPage) {
        trackPage(nextPage);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
}
