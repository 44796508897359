import {Button, Popover} from 'antd';
const content = (
  <div>
    <p>This is coming soon</p>
  </div>
);
const EmbedPopover = (props) => {
  return (
    <Popover content={content} title="Embed Options" trigger="click">
      <Button type="link">Embed</Button>
    </Popover>
  );
};
export default EmbedPopover;
