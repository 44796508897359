import React from 'react';
import dayjs from 'dayjs';

export default function landlordNoticetoEnter(props) {
  const {
    yourName,
    yourAddress,
    yourCity,
    yourState,
    yourZip,
    yourPhone,
    yourEmail,
    noticeDate,
    tenantName,
    tenantAddress,
    tenantState,
    entryDatetime,
    reasonsForEntry,
    hoursNotice
  } = props;
  return (
    <>
      <p>{yourName}</p>
      <p>{yourAddress}</p>
      <p>
        {yourCity}, {yourState} {yourZip}
      </p>
      <p>{yourPhone}</p>
      <p>{yourEmail}</p>
      <br />
      <p>{dayjs(noticeDate).format('MMMM DD, YYYY')}</p>
      <br />
      <p>
        <strong>RE: NOTICE TO ENTER THE PREMISES</strong>
      </p>
      <br />
      <p>Dear {tenantName},</p>
      <br />
      <p>
        In accordance with {tenantState} landlord-tenant laws and the terms of
        your lease agreement, this letter serves as advanced notice that we
        require access to your rental property located at {tenantAddress} for
        the following purpose:
        <br />
        <br /> {reasonsForEntry}
        <br />
        <br />
        As required by law, this notice is being provided at least {
          hoursNotice
        }{' '}
        hours before the planned entry. Therefore, access to the property will
        be approximately scheduled for{' '}
        {dayjs(entryDatetime).format('MMMM DD, YYYY @ h:mmA')}. If you have any
        concerns or if the proposed date and time are not suitable for you,
        please contact us immediately to reschedule. We appreciate your
        cooperation in this matter and your continued efforts to maintain the
        rental property.
      </p>
      <br />
      <p>Sincerely,</p>
      <p>{yourName}</p>
    </>
  );
}
