const daysOfMonth = [
  { key: '1', label: '1st', value: '0' },
  { key: '2', label: '2nd', value: '1' },
  { key: '3', label: '3rd', value: '2' },
  { key: '4', label: '4th', value: '3' },
  { key: '5', label: '5th', value: '4' },
  { key: '6', label: '6th', value: '5' },
  { key: '7', label: '7th', value: '6' },
  { key: '8', label: '8th', value: '7' },
  { key: '9', label: '9th', value: '8' },
  { key: '10', label: '10th', value: '9' },
  { key: '11', label: '11th', value: '10' },
  { key: '12', label: '12th', value: '11' },
  { key: '13', label: '13th', value: '12' },
  { key: '14', label: '14th', value: '13' },
  { key: '15', label: '15th', value: '14' },
  { key: '16', label: '16th', value: '15' },
  { key: '17', label: '17th', value: '16' },
  { key: '18', label: '18th', value: '17' },
  { key: '19', label: '19th', value: '18' },
  { key: '20', label: '20th', value: '19' },
  { key: '21', label: '21st', value: '20' },
  { key: '22', label: '22nd', value: '21' },
  { key: '23', label: '23rd', value: '22' },
  { key: '24', label: '24th', value: '23' },
  { key: '25', label: '25th', value: '24' },
  { key: '26', label: '26th', value: '25' },
  { key: '27', label: '27th', value: '26' },
  { key: '28', label: '28th', value: '27' },
  { key: '29', label: '29th', value: '28' },
  { key: '30', label: '30th', value: '29' },
  { key: '31', label: '31st', value: '30' },
];
export default daysOfMonth;
