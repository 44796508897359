import {Button, message} from 'antd';
import React, {useContext, useState} from 'react';
import {signInWithCustomToken} from 'firebase/auth';
import {httpsCallable} from 'firebase/functions';
import {setUserId, logEvent} from 'firebase/analytics';
import {DocumentCreatorContext} from '../../context/DocumentCreatorContext';
import {loginUser} from '../../services/magic';
import {
  functions,
  auth as firebaseAuth,
  analytics
} from '../../library/firebase/firebase';

export default function MagicAuth({
  title,
  email,
  setIsReadOnly,
  ...buttonProps
}) {
  // console.log('[MagicAuth.js]', email);
  const [loading, setLoading] = useState(false);
  const dispatch = useContext(DocumentCreatorContext)[1];

  const handleAuthenticate = async (email) => {
    console.log('ƒ handleAuthenticate', email);
    // window.dataLayer.push({
    //   event: 'docgpt_auth',
    //   eventProps: {
    //     category: 'Button',
    //     label: 'magic_auth',
    //     action: 'authenticate',
    //     value: 0
    //   }
    // });
    logEvent(analytics, 'docgpt_auth', {
      category: 'Button',
      label: 'magic_auth',
      action: 'authenticate',
      value: 0
    });
    
    setLoading(true);
    if (!email) {
      message.error('Valid email is required');
      setLoading(false);
      return;
    }

    try {
      const didToken = await loginUser(email);

      const auth = httpsCallable(functions, 'magic');
      let result = (await auth({didToken})).data;
      setUserId(analytics, result.uid);
      if (result.isNewUser) {
        logEvent(analytics, 'sign_up', {method: 'Magic'});
      }

      const authUser = await signInWithCustomToken(firebaseAuth, result.token);
      // console.log('[MagicAuth.js] ƒ handleLogin', result, authUser);

      if (authUser) {
        dispatch({
          type: 'SET_AUTH',
          payload: {
            user: {
              ...result,
              authenticated: true,
              email
            }
          }
        });
        dispatch({type: 'TOGGLE_LOGIN_MODAL', payload: false});
      }
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  return (
    <Button
      loading={loading}
      type="primary"
      onClick={() => handleAuthenticate(email)}
      {...buttonProps}
    >
      {title}
    </Button>
  );
}
