import React from 'react';
import MaskedInput from 'react-text-mask';
import styled from 'styled-components';
import PropTypes from 'prop-types';

export default function PhoneInput(props) {
  return (
    <StyledPhoneInput
      className="ant-input"
      mask={[
        '+',
        '1',
        ' ',
        '(',
        /[2-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/
      ]}
      {...props}
      
    />
  );
}

const StyledPhoneInput = styled(MaskedInput)`
  width: 100%;
  font-size: 14px;
  line-height: 18.4px;
  padding: 6px;
  ::placeholder {
    color: rgba(0, 0, 0, 0.25);
    font-size: 14px;
  }
`;
PhoneInput.defaultProps = {
  guide: false,
  showMask: true,
  placeholder: '+1 (321) 555-1212'
};

PhoneInput.propTypes = {
  guide: PropTypes.bool,
  showMask: PropTypes.bool,
  placeholder: PropTypes.string
};
