import styled from 'styled-components';
import { Card, Button, Form } from 'antd';

export const StyledCard = styled(Card)`
  color: #122c34;
  width: 360px;
  display: block;
  margin: 0 auto;
  -webkit-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  .ant-card-head-title {
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    text-align: left;
    color: #fff;
    border-botton: none;
    text-overflow: ellipsis;
  }
  .ant-card-head {
    background-color: #541388;
  }
`;

export const StyledCalculatorButton = styled(Button)`
  background-color: #ffcc00;
  font-weight: 600;
  color: #541388;
  border: none;
  &:active,
  :hover,
  :focus {
    background-color: #541388;
  }
`;

export const StyledCalculatorForm = styled(Form)`
  label {
    font-size: 14px;
  }
  .ant-form-item-label {
    padding: 0;
  }
`;

export const WidgetWrapper = styled.div`
  padding: 24px;
  width: 100%;
`;
