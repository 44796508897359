import {useState} from 'react';
import {costCalculator} from '../library/helpers/calculators';
import {Form, Input, InputNumber, Space, Row, Col, Select} from 'antd';
import {StyledCalculatorButton, StyledCalculatorForm} from '../App.styles';
import {motion} from 'framer-motion';
import verifyEmail from '../library/apis/verifyEmail';
import createLead from '../library/apis/freshsalesLead';
import ServicePlanCard from '../components/ServicePlanCard/ServicePlanCard';
import CustomPricingCard from '../components/CustomPricingCard/CustomPricingCard';
const {Option} = Select;
const Item = Form.Item;
export default function PricingCalculator() {
  const [state, setState] = useState({
    showCosts: false,
    units: 0,
    email: '',
    planCosts: [],
    errors: {},
    loading: false
  });
  const [form] = Form.useForm();

  const handleFinish = async (formData) => {
    //console.log(formData);
    // verify email
    setState((prevState) => {
      return {...prevState, loading: true, units: formData.unitCount};
    });

    try {
      const verifyRes = await verifyEmail(formData.email);
      if (verifyRes.status !== 200) {
        setState((prevState) => ({
          ...prevState,
          loading: false,
          errors: {
            email: {
              success: false,
              message: 'There was a problem verifying your email address'
            }
          }
        }));
        throw new Error(verifyRes);
      }

      if (
        verifyRes.data.valid === false ||
        verifyRes.data.disposable === true
      ) {
        return setState((prevState) => ({
          ...prevState,
          loading: false,
          errors: {
            email: {success: false, message: 'This is not a valid email'}
          }
        }));
      } else {
        const leadRes = await createLead(formData);
        if (leadRes.status !== 200) {
          return setState((prevState) => ({
            ...prevState,
            loading: false,
            errors: {
              success: false,
              message: 'There was a problem with ƒ createLead'
            }
          }));
        }

        const costArr = costCalculator(formData.unitCount);
        return setState((prevState) => {
          return {
            ...prevState,
            planCosts: costArr,
            loading: false
          };
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const validateMessages = {
    required: 'Required input'
  };

  const inputFields = (
    <div className="pricing-calculator">
      <StyledCalculatorForm
        form={form}
        requiredMark={false}
        onFinish={handleFinish}
        layout="vertical"
        validateMessages={validateMessages}
        size={'large'}
      >
        <Space direction="horizontal" size={[8, 0]} align={'end'} wrap>
          <Item name="unitCount" label="Unit Qty." rules={[{required: true}]}>
            <InputNumber
              min={1}
              placeholder="Unit Qty."
              style={{width: '120px'}}
            />
          </Item>
          <Item
            name="propertyType"
            label="Property Type"
            rules={[{required: true}]}
          >
            <Select
              placeholder="Select Type"
              style={{width: '160px', textAlign: 'left'}}
              showArrow={false}
            >
              <Option key="1" value="Single Family Residential">
                Single Family
              </Option>
              <Option key="2" value="Multi-Family Residential">
                Multi-Family
              </Option>
              <Option key="3" value="Commercial">
                Commercial
              </Option>
              <Option key="4" value="Mixed use Commercial">
                Mixed Use Commercial
              </Option>
              <Option key="5" value="Manufactured Housing">
                Manufactured Housing
              </Option>
              <Option key="6" value="Land">
                Land
              </Option>
            </Select>
          </Item>
          <Item
            name="email"
            label="Email Address"
            hasFeedback
            rules={[{required: true, type: 'email'}]}
            // validateStatus={state.errors.email ? 'error' : 'success'}
            help={state.errors.email && state.errors.email.message}
          >
            <Input type="email" placeholder="Enter email address" />
          </Item>
          <Item>
            <StyledCalculatorButton
              type="primary"
              htmlType="submit"
              loading={state.loading}
            >
              CALCULATE
            </StyledCalculatorButton>
          </Item>
        </Space>
      </StyledCalculatorForm>
    </div>
  );

  const cardVariants = {
    visible: {
      transition: {
        when: 'beforeChildren',
        staggerChildren: 0.05
      }
    },
    hidden: {
      transition: {
        when: 'afterChildren'
      }
    }
  };

  const costResults = (
    <motion.div
      className="pricing-calculator"
      initial="hidden"
      animate="visible"
      variants={cardVariants}
    >
      <Row align="center">
        {state.units >= 300 ? (
          <Col span={24}>
            <CustomPricingCard />
          </Col>
        ) : (
          state.planCosts.map((props, index) => {
            return (
              <Col xs={24} sm={16} md={8} key={index}>
                <ServicePlanCard {...props} />
              </Col>
            );
          })
        )}
      </Row>
    </motion.div>
  );

  return state.planCosts.length === 0 ? inputFields : costResults;
}
