import React from 'react';
import dayjs from 'dayjs';

export default function mailCarrierMailboxNote(props) {
  const {formerTenantName, currentTenantName, landlordName, effectiveDate} =
    props;
  return (
    <>
      <h1>Dear Mail Carrier,</h1>
      <br />
      <h1>
        As of {dayjs(effectiveDate).format('MMMM DD, YYYY')},
        the former tenant {formerTenantName} no longer lives at this address,
        please only leave mail for the current tenant {currentTenantName}.
      </h1>
      <br />
      <h1>Sincerely,</h1>
      <h1>{landlordName}</h1>
    </>
  );
}
