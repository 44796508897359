import React, { useRef } from 'react'
import { useReactToPrint } from 'react-to-print';
import CapRateCalculator from '../containers/CapRateCalculator';

export default function CapRateCalculatorApp() {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Cap Rate Calculator'
  });
  return <CapRateCalculator ref={componentRef} handlePrint={handlePrint} />;
};
