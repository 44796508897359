import React from 'react';
import Card from '../uielements/Card/Card';
import SendForm from '../documentForms/SendForm';
import ResetButton from '../ResetButton/ResetButton';

export default function SendTab(props) {
  const {setActiveTab} = props;

  return (
    <Card title="Deliver" extra={<ResetButton />}>
      <SendForm setActiveTab={setActiveTab} />
    </Card>
  );
}
