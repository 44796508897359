export default function reducer(state, action) {
  const type = action.type?.toUpperCase();
  switch (type) {
    case 'SET_AUTH':
      return {
        ...state,
        ...action.payload
      };
    case 'SELECT_DOCUMENT':
      return {
        ...state,
        ...action.payload
      };
    case 'SET_DOCUMENT_LIST':
      return {...state, documentList: action.payload};
    case 'SET_DOCUMENT':
      return {...state, document: action.payload};
    case 'SET_PROMPT_FORM_DATA':
      return {...state, promptFormData: action.payload};
    case 'TOGGLE_LOGIN_MODAL':
      return {...state, loginModalOpen: action.payload};
    default:
      return state;
  }
}
