import React from 'react';
import {ResultWrapper} from '../components.styles';
import {StyledNumber, ErrorText} from '../components.styles';
import {List, Typography} from 'antd';
import numeral from 'numeral';
import {useSprings} from 'react-spring';
const {Text} = Typography;

const RentSplitResult = (props) => {
  const {bedrooms, title} = props;
  //console.log(bedrooms);

  const numberSprings = useSprings(
    bedrooms.length,
    bedrooms.map((bedroom) => ({
      from: {
        num: 0
      },
      to: {
        num: bedroom.perOccupantRent
      }
    }))
  );

  return (
    <ResultWrapper style={{textAlign: 'left'}}>
      <Text style={{color: '#122c34', fontWeight: '700'}}>{title}: </Text>
      <br />
      {!bedrooms.length ? <ErrorText>No bedrooms yet. </ErrorText> : ''}
      <List
        size="small"
        dataSource={bedrooms}
        renderItem={(bedroom, index) => (
          <List.Item>
            <List.Item.Meta
              title={`Bedroom ${index + 1}:`}
              description={
                <>
                  <StyledNumber>
                    {bedroom.perOccupantRent
                      ? numberSprings[index].num.to((v) =>
                          numeral(v).format('$0,0.00')
                        )
                      : '$0'}
                  </StyledNumber>
                  <StyledNumber> per person</StyledNumber>
                </>
              }
            />
          </List.Item>
        )}
      />
      {/* <StyledNumber>
        {numberSprings[0].num.to((v) => numeral(v).format('$0,0.00'))}
      </StyledNumber> */}
    </ResultWrapper>
  );
};
export default RentSplitResult;
