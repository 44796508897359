import axios from 'axios';
import {getToken, getLimitedUseToken} from 'firebase/app-check';
import {appCheck} from './firebase';

const getAppCheckToken = async () => {
  try {
    const appCheckTokenResponse = await getToken(appCheck, false);
    return appCheckTokenResponse.token;
  } catch (err) {
    console.error(err);
  }
};

const firebaseHttpClient = axios.create({
  baseURL: process.env.REACT_APP_FIREBASE_API_URL,
  headers: {
    'x-api-key': process.env.REACT_APP_FIREBASE_API_KEY,
    'X-Firebase-AppCheck': getAppCheckToken()
  }
});

export async function sendMail(body) {
  const options = {
    url: '/sib/send-mail',
    method: 'POST',
    data: body
  };

  try {
    const result = await firebaseHttpClient(options);
    return result;
  } catch (err) {
    throw err;
  }
}

export async function upsertContact(data) {
  const options = {
    url: '/sib/contact',
    method: 'POST',
    data
  };

  try {
    const result = await firebaseHttpClient(options);
    return result;
  } catch (err) {
    throw err;
  }
}
