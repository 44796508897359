import React from 'react';


export default function helloWorld(props) {
  const {fullName} = props;
  return (
    <>
      <h1>Hello {fullName},</h1>
      <br />
      <p>It's so nice to have you back where you belong.</p>
      <p>
        You're lookin' <strong>swell</strong>, {fullName} &#128521;
      </p>
      <p>I can tell, {fullName}</p>
      <p>
        <em>
          You're still glowin', <br />
          you're still crowin' <br />
          You're still goin' strong
        </em>
      </p>
      <br />
      <ul>
        <li>
          <h4>{fullName}'ll never go away</h4>
        </li>
        <li>
          <h3>{fullName}'ll never go away</h3>
        </li>
        <li>
          <h2>{fullName}'ll never go away again</h2>
        </li>
      </ul>
      <br />
      <p>Sincerely,</p>
      <p>Louis Armstrong</p>
    </>
  );
}
