import React from 'react';
import {Dropdown, Button} from 'antd';
import {DownOutlined} from '@ant-design/icons';
import useDocumentList from '../../hooks/useDocumentList';

export default function DocumentDropdown(props) {
  const {documentList, status} = useDocumentList();
  return (
    <Dropdown
      trigger={['click']}
      menu={{
        items: documentList
      }}
    >
      <Button block size="large" type="primary" loading={status === 'loading'}>
        Select Document
        <DownOutlined style={{color: '#fff'}} />
      </Button>
    </Dropdown>
  );
}
