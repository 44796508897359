import React from 'react';
import { Card } from 'antd';
import { motion } from 'framer-motion';
import BestValueBanner from './BestValueBanner';

const card = {
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      type: 'spring',
      bounce: 1,
      stiffness: 200,
    },
  },
  hidden: {
    opacity: 0,
    scale: 0,
  },
};

export default function ServicePlanCard(props) {
  //console.log('ServicePlanCard', props);
  const { servicePlanName, totalCost, subscriptionCost, achCost, bestValue } =
    props;
  return (
    <motion.div variants={card}>
      <Card
        style={
          bestValue
            ? {
                border: '1px solid #dfdfdf',
                boxShadow: '0px 0px 12px 2px rgba(0, 0, 0, 0.2)',
                zIndex: '2',
              }
            : { border: '1px solid #dfdfdf' }
        }
      >
        {bestValue && BestValueBanner}
        <h3>
          <strong>{servicePlanName.toUpperCase()}</strong>
        </h3>
        <h4>ESTIMATED MONTHLY COST</h4>
        <span style={{ fontSize: '32px' }}>${totalCost}</span>
        <span> per month</span>
        <div className="cost-breakdown">
          <h4>Cost Breakdown:</h4>
          <h4>${subscriptionCost} Subscription Fee</h4>
          <h4>${achCost} ACH Fees</h4>
        </div>
      </Card>
    </motion.div>
  );
}
