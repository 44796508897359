import { Popover, Space } from 'antd';
import { LinkedinShare, FacebookShare, EmailShare } from './SocialButtons';
function SocialShare(props) {
  return (
    <Popover
      {...props}
      content={
        <Space>
          <LinkedinShare hidePopover={props.hidePopover} />
          <FacebookShare hidePopover={props.hidePopover} />
          <EmailShare hidePopover={props.hidePopover} />
        </Space>
      }
    />
  );
}

export default SocialShare;
