import {Col, Layout, Row, Empty, Button} from 'antd';
import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import {DocumentCreatorContext} from '../../context/DocumentCreatorContext/index';
import TextEditor from '../TextEditor/TextEditor';
import Card from '../uielements/Card/Card';
import SendForm from '../documentForms/SendForm';
import PromptForm from '../documentForms/PromptForm';
import DocumentDropDown from '../DocumentDropdown/DocumentDropdown';
import {useFirestore, useFirebaseApp, useFirestoreDocData} from 'reactfire';
import {doc} from 'firebase/firestore';
import LoginModal from '../LoginModal/LoginModal';
import useSigninSignout from '../../hooks/useSigninSignout';
import ResetButton from '../ResetButton/ResetButton';
import {CheckCircleFilled} from '@ant-design/icons';
import {logEvent} from 'firebase/analytics';
import {analytics} from '../../library/firebase/firebase';

const {Content, Footer} = Layout;

export default function DocCreatorDesktop(props) {
  const [state, dispatch] = useContext(DocumentCreatorContext);
  const [editButtonLoading, setEditButtonLoading] = useState(false);
  const {login} = useSigninSignout();
  // console.log(state);
  const db = useFirestore(useFirebaseApp());
  const docRef = doc(db, 'templates', props.templateId || 'undefined');
  const {status, data: template} = useFirestoreDocData(docRef);

  const handleEdit = async () => {
    // window.dataLayer.push({
    //   event: 'docgpt_edit',
    //   eventProps: {
    //     category: 'Button',
    //     label: 'docgpt_edit',
    //     action: 'edit',
    //     value: 0
    //   }
    // });

    logEvent(analytics, 'docgpt_edit', {
      category: 'Button',
      label: 'docgpt_edit',
      action: 'edit',
      value: 0
    });

    setEditButtonLoading(true);
    try {
      await login();
    } catch (err) {
      console.error(err);
    }
    setEditButtonLoading(false);
  };

  useEffect(() => {
    if (status === 'success' && props.templateId && template) {
      dispatch({
        type: 'SELECT_DOCUMENT',
        payload: {selectedDocument: template, selectedKey: props.templateId}
      });
    }
  }, [status, props.templateId, dispatch, template]);

  return (
    <StyledLayout>
      <Content>
        <div
          className="container"
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '36px',
            width: '100%'
          }}
        >
          <LoginModal />
          <Row gutter={24} style={{flex: '0 1 60px'}}>
            <Col span={6}>
              <DocumentDropDown />
            </Col>
          </Row>
          <Row gutter={24} style={{flex: '1 0 auto'}}>
            <Col span={6}>
              <Card style={{height: '100%'}} title="Customize">
                {state.selectedKey ? (
                  <PromptForm formSchema={state.selectedDocument} />
                ) : (
                  <Empty
                    description={
                      <span>
                        Please select a document from the Select Document
                        dropdown menu
                      </span>
                    }
                  />
                )}
              </Card>
            </Col>
            <Col span={12}>
              <Card
                title="Review"
                style={{height: '100%'}}
                bodyStyle={{height: 'calc(100% - 59px'}}
                extra={
                  <Button
                    type={state.user.authenticated ? 'primary' : 'default'}
                    ghost={state.user.authenticated}
                    loading={editButtonLoading}
                    onClick={handleEdit}
                    disabled={!state.document}
                  >
                    Edit
                    {state.user.authenticated && (
                      <CheckCircleFilled style={{color: 'rgb(82,196,26)'}} />
                    )}
                  </Button>
                }
              >
                <TextEditor
                  readOnly={!state.user.authenticated || !state.document}
                />
              </Card>
            </Col>
            <Col span={6}>
              <Card
                style={{height: '100%'}}
                title="Deliver"
                extra={<ResetButton />}
              >
                <SendForm />
              </Card>
            </Col>
          </Row>
        </div>
      </Content>
      <Footer>
        &copy; {dayjs().format('YYYY')} PayRent Inc. All rights reserved.
      </Footer>
    </StyledLayout>
  );
}

const StyledLayout = styled(Layout)`
  .ant-layout-content {
    height: calc(100vh - 70px);
    margin: 0 auto;
    max-width: 1920px;
    overflow-y: auto;
  }
  .ant-layout-footer {
    text-align: center;
  }
`;
