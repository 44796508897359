import React from 'react';
import { StyledCard, WidgetWrapper } from '../App.styles';
import CalculatorForm from '../components/calculatorforms/NetOperatingIncomeCalculatorForm';
import { Actions } from '../library/helpers/actions';
import Logo from '../components/Logo/Logo';

class NetOperatingIncomeCalculator extends React.PureComponent {
  render() {
    return (
      <WidgetWrapper className="noi-widget">
        <StyledCard
          title="Net Operating Income"
          className="noi-card"
          extra={
            <Actions
              handlePrint={this.props.handlePrint}
              showAction={['print', 'share']}
            />
          }
          ref={this.props.ref}
        >
          <CalculatorForm />
          <Logo />
        </StyledCard>
      </WidgetWrapper>
    );
  }
}

export default NetOperatingIncomeCalculator;
