import React from 'react';

export default function HtmlToPdfTemplate({children}) {
  const styles = {
    page: {
      margin: '72pt',
      pageBreakAfter: 'always',
      width: '480pt'
    },

    columnLayout: {
      display: 'block',
      margin: '0 auto'
    },

    column: {
      display: 'block'
    }
  };

  return (
    <div style={styles.page}>
      <div style={styles.columnLayout}>
        <div style={styles.column}>{children}</div>
      </div>
    </div>
  );
}
