import React, {useRef} from 'react';
import MoveInCalculator from '../containers/MoveInCalculator';
import {useReactToPrint} from 'react-to-print';

export default function MoveInCalculatorApp() {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Move-In Calculator'
  });
  return <MoveInCalculator ref={componentRef} handlePrint={handlePrint} />;
}
