import React from 'react';
import { StyledCard, WidgetWrapper } from '../App.styles';
import CalculatorForm from '../components/calculatorforms/MoveInCalculatorForm';
import { Actions } from '../library/helpers/actions';
import Logo from '../components/Logo/Logo';

class MoveInCalculator extends React.PureComponent {
  render() {
    return (
      <WidgetWrapper className="move-in-widget">
        <StyledCard
          title="Move-In Calculator"
          className="move-in-widget"
          extra={
            <Actions
              handlePrint={this.props.handlePrint}
              showAction={['print', 'share']}
            />
          }
          ref={this.ref}
        >
          <CalculatorForm />
          <Logo />
        </StyledCard>
      </WidgetWrapper>
    );
  }
}

export default MoveInCalculator;
