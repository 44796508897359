import React, {useContext, useState} from 'react';
import { Button, message } from 'antd';
import {Button as MobileButton} from 'antd-mobile';
import {checkUser, logoutUser} from '../../services/magic';
import {DocumentCreatorContext} from '../../context/DocumentCreatorContext';
import {signOutUser} from '../../library/firebase/firebase.authentication.util';
import {isMobile} from 'react-device-detect';

export default function ResetButton() {
  const [state, dispatch] = useContext(DocumentCreatorContext);
  const [resetButtonLoading, setResetLoadingButton] = useState(false);

  const handleLogout = async () => {
    setResetLoadingButton(true);
    try {
      await checkUser().then(async ({isLoggedIn}) => {
        isLoggedIn && (await logoutUser());
      });
      const authSignout = await signOutUser();
      if (authSignout) {
        dispatch({
          type: 'SET_AUTH',
          payload: {
            user: {
              authenticated: false,
              email: null,
              token: null,
              uid: null
            }
          }
        });
        message.success('Form and email verification reset');
      }
    } catch (err) {
      console.error(err);
    }
    setResetLoadingButton(false);
  };

  if (isMobile) {
    return (
      <MobileButton
        size='small'
        fill='outline'
        color='primary'
        onClick={handleLogout}
        form="send-form"
        type="reset"
        loading={resetButtonLoading}
        disabled={!state.user.authenticated}
      >
        Reset
      </MobileButton>
    );
  }

  return (
    <Button
      type={state.user.authenticated ? 'primary' : 'default'}
      ghost={state.user.authenticated}
      onClick={handleLogout}
      form="send-form"
      htmlType="reset"
      loading={resetButtonLoading}
      disabled={!state.user.authenticated}
    >
      Reset
    </Button>
  );
}
