import {useContext, useEffect, useCallback, useMemo} from 'react';
import {DocumentCreatorContext} from '../context/DocumentCreatorContext';
import {useFirestore, useFirestoreCollectionData} from 'reactfire';
import {query, collection, orderBy} from 'firebase/firestore';

export default function useDocumentList() {
  const dispatch = useContext(DocumentCreatorContext)[1];
  const firestore = useFirestore();
  const templatesCollection = collection(firestore, 'templates');
  const templatesQuery = query(
    templatesCollection,
    orderBy('metadata.label', 'asc')
  );
  const {status, data: templates} = useFirestoreCollectionData(templatesQuery, {
    idField: 'key'
  });

  let docList = useMemo(
    () => [
      {
        key: 'landlord',
        type: 'group',
        label: <strong>Landlord Documents</strong>,
        children: []
      },
      {
        key: 'tenant',
        type: 'group',
        label: <strong>Tenant Documents</strong>,
        children: []
      }
    ],
    []
  );

  const createDocumentList = useCallback(() => {
    if (templates.length > 0) {
      for (const t of templates) {
        const category = t.metadata.category;
        const index = docList.findIndex((i) => i.key === category);
        if (index !== -1) {
          const newDocList = [...docList[index].children];
          newDocList.push({
            key: t.key,
            label: t.metadata.label,
            onClick: () =>
              dispatch({
                type: 'SELECT_DOCUMENT',
                payload: {
                  selectedKey: t.key,
                  selectedDocument: t,
                  document: '',
                  promptFormData: {}
                }
              })
          });

          docList[index].children = newDocList.sort((a, b) =>
            a.label < b.label ? -1 : a.label > b.label ? 1 : 0
          );
        }
      }
    }
  }, [dispatch, docList, templates]);

  useEffect(() => {
    if (status === 'success' && templates.length) {
      createDocumentList();
      dispatch({type: 'SET_DOCUMENT_LIST', payload: templates});
    }
  }, [status, createDocumentList, dispatch, templates]);

  return {documentList: docList, status};
}
