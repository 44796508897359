import { Select } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import monthsOfYear from './monthsOfYear';

function MonthSelect(props) {
  const { handleMonthChange } = props;
  return (
    <Select
      className="month-select"
      onChange={handleMonthChange}
      placeholder="Select month"
      showSearch
      style={{ width: 148 }}
      options={monthsOfYear}
      suffixIcon={<DownOutlined style={{ fontSize: '14px' }} />}
    />
  );
}
export default MonthSelect;
