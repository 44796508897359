import { ResultWrapper } from '../components.styles';
import { StyledNumber, ErrorText } from '../components.styles';
import { Typography, Row, Col } from 'antd';
import numeral from 'numeral';
import { useSpring } from 'react-spring';
const { Text } = Typography;

const CapRateResult = (props) => {
  //console.log('ƒ CapRateResult', props);
  const { title, capRate } = props;
  const capRateSpring = useSpring({
    from: { num: 0 },
    to: { num: capRate || 0 },
  });

  return (
    <>
      <ResultWrapper>
        <Row>
          <Col span={24}>
            <Text style={{ color: '#122c34', fontWeight: '700' }}>{title}</Text>
            <br />
            {capRateSpring < 0 ? (
              <ErrorText>Error. Check your settings. </ErrorText>
            ) : (
              ''
            )}
            <StyledNumber>
              {capRateSpring.num.to((v) => numeral(v).format('0.00%'))}
            </StyledNumber>
          </Col>
        </Row>
      </ResultWrapper>
    </>
  );
};
export default CapRateResult;
