import { useState, useEffect } from 'react';
import { Form, Input, Space } from 'antd';
import { netOperatingIncome } from '../../library/helpers/calculators';
import { Typography } from 'antd';
import NetOperatingIncomeResult from '../NetOperatingIncomeResult/NetOperatingIncomeResult';
const { Title } = Typography;

const CalculatorForm = (props) => {
  const [calculatorVals, setCalculatorVals] = useState({
    inputs: {},
    annualIncome: 0,
    annualExpenses: 0,
    netOperatingIncome: 0,
    operatingExpenseRatio: 0
  });

  useEffect(() => {
    //console.log(calculatorVals.inputs);
    const {
      rentIncome,
      otherIncome,
      propertyTaxes,
      insurance,
      utilities,
      repairs,
      managementFees,
      otherExpense
    } = calculatorVals.inputs;
    if (
      rentIncome &&
      otherIncome &&
      propertyTaxes &&
      insurance &&
      utilities &&
      repairs &&
      managementFees &&
      otherExpense
    ) {
      let calculatedCosts = netOperatingIncome(calculatorVals.inputs);
      //console.log(calculatedCosts);
      setCalculatorVals((prevCalculatorVals) => {
        return {
          ...prevCalculatorVals,
          annualIncome: calculatedCosts.annualIncome,
          annualExpenses: calculatedCosts.annualExpenses,
          netOperatingIncome: calculatedCosts.netOperatingIncome,
          operatingExpenseRatio: calculatedCosts.operatingExpenseRatio
        };
      });
    }
  }, [calculatorVals.inputs]);
  return (
    <Form
      layout="vertical"
      name="NoiCalculator"
      onValuesChange={(changedValues) => {
        const key = Object.keys(changedValues)[0];
        const numericValue = parseFloat(Object.values(changedValues)[0]);
        changedValues[key] = numericValue;
        //console.log(changedValues);
        const prevCalulatorVals = calculatorVals;
        setCalculatorVals({
          ...prevCalulatorVals,
          inputs: { ...prevCalulatorVals.inputs, ...changedValues }
        });
      }}
    >
      <Title level={3}>
        Income <span style={{ fontSize: '14px' }}>(monthly)</span>
      </Title>
      <Space size={14}>
        <Form.Item label="Rent" name="rentIncome">
          <Input prefix="$" />
        </Form.Item>
        <Form.Item label="Other Income" name="otherIncome">
          <Input prefix="$" />
        </Form.Item>
      </Space>
      <Title level={3}>
        Expenses <span style={{ fontSize: '14px' }}>(annual)</span>
      </Title>
      <Space size={14}>
        <Form.Item label="Property Taxes" name="propertyTaxes">
          <Input prefix="$" />
        </Form.Item>
        <Form.Item label="Insurance" name="insurance">
          <Input prefix="$" />
        </Form.Item>
      </Space>
      <Space size={14}>
        <Form.Item label="Utilities" name="utilities">
          <Input prefix="$" />
        </Form.Item>
        <Form.Item label="Repairs and Maint." name="repairs">
          <Input prefix="$" />
        </Form.Item>
      </Space>
      <Space size={14}>
        <Form.Item label="Management Fees" name="managementFees">
          <Input prefix="$" />
        </Form.Item>
        <Form.Item label="Other Expenses" name="otherExpense">
          <Input prefix="$" />
        </Form.Item>
      </Space>
      <NetOperatingIncomeResult
        {...calculatorVals}
        title="Net Operating Income"
      />
    </Form>
  );
};
export default CalculatorForm;
