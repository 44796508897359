import styled from 'styled-components';
import { Select, Typography, Card, Layout, Input, Drawer, Spin } from 'antd';
import { animated as a } from 'react-spring';
import PDFViewer from 'mgr-pdf-viewer-react';
import {
  PrinterOutlined,
  SendOutlined,
  ShareAltOutlined,
  PaperClipOutlined,
  FileDoneOutlined,
  CloudDownloadOutlined,
} from '@ant-design/icons';
import { Tree } from 'antd';
const { DirectoryTree } = Tree;
const { Text } = Typography;
const { Header, Footer } = Layout;

export const ResultWrapper = styled.div`
  background-color: #fff;
  border: 1px solid #cb47b8;
  border-radius: 3px;
  padding: 12px 18px;
  text-align: right;
`;

const AnimatedNumber = a(Text);
export const StyledNumber = styled(AnimatedNumber)`
  color: #cb47b8;
  font-weight: 700;
  .animated-number {
    display: inline-block;
    height: 1em;
    line-height: 1em;
  }

  .digit {
    display: inline-block;
    width: 0.6em;
    height: 1.3em;
    overflow: hidden;
  }

  .digit.narrow {
    width: 0.3em;
  }
`;

export const ErrorText = styled(Text)`
  font-size: 9px;
  color: red;
`;

export const StyledPrinterOultined = styled(PrinterOutlined)`
  color: #fff;
  font-size: 20px;
`;
export const StyledSendOutlined = styled(SendOutlined)`
  color: #fff;
  font-size: 20px;
`;
export const StyledShareAltOutlined = styled(ShareAltOutlined)`
  color: #fff;
  font-size: 20px;
`;

export const StyledLogo = styled.div`
  text-align: center;
  margin-top: 24px;
  img {
    width: 25%;
  }
  .ant-btn {
    color: #cb47b8;
  }
`;

export const LandlordFromsWrapper = styled.div`
  min-width: 768px;
  max-width: 960px;
  display: block;
  margin: 0 auto;
`;

export const StyledLandlordFrom = styled(Card)`
  width: 1024;
  min-width: 680px;
  margin: 0 auto;

  .ant-card-head-title {
    font-weight: 600;
    font-size: 1.4em;
    padding: 8px 0;
  }
  .ant-card-body {
    height: 910px;
    overflow: auto;
  }
  .ant-card-head {
    border-bottom: none;
    background: #541388;
  }
`;

export const StyledLandlordFromSelect = styled(Select)`
  margin: 10px;
`;
export const StyledLandlordFromInput = styled(Input)`
  border: none;
  border-bottom: 1px solid grey;
  display: inline;
`;

export const StyledLegalHeader = styled(Header)`
  background: white;
`;

export const StyledLegalTitle = styled.div`
  text-align: center;
`;

export const StyledLegalFooter = styled(Footer)`
  background: white;
`;

export const StyledPaperClipOutlined = styled(PaperClipOutlined)`
  color: #fff;
  font-size: 20px;
`;
export const StyledCloudDownloadOutlined = styled(CloudDownloadOutlined)`
  color: #fff;
  font-size: 20px;
`;

export const StyledFileDoneOutlined = styled(FileDoneOutlined)`
  color: #fff;
  font-size: 20px;
`;

export const StyledLandlordFromsDrawer = styled(Drawer)`
  position: absolute;
  overflow: 'auto';
  .ant-drawer-header {
    background: #541388;
  }
  .ant-drawer-title {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    font-family: 'Poppins', 'Open-Sans', sans-serif;
  }
  .ant-drawer-close .anticon {
    color: #fff;
    font-size: 20px;
  }
`;

export const StyledLandlordFormsContent = styled.div`
  @media screen {
    margin: 0;
  }
  @media print {
    margin: 0;
  }
  @media screen, print {
    font-family: 'Times New Roman', Times, serif;
    font-size: 1.2em;
  }
`;

export const StyledSpin = styled(Spin)`
  background: rgba(256, 256, 256, 0.15);
  width: 100%;
  height: 100%;
  .ant-spin-dot {
    position: absolute;
    top: 25%;
  }
`;

export const StyledPDFViewer = styled(PDFViewer)``;

export const StyledFormHeader = styled.div`
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  text-align: center;
  border-botton: none;
  text-overflow: ellipsis;
`;

export const StyledDirectoryTree = styled(DirectoryTree)``;
