import logo from '../../payrent_logo.svg';
import { Button } from 'antd';
import { StyledLogo } from '../components.styles';

function Logo() {
  return (
    <StyledLogo>
      <a href="https://www.payrent.com" target="_blank" rel="noreferrer">
        <img src={logo} className="payrent-logo" alt="PayRent" />
      </a>
      <br />
      <Button type="link" href="https://www.payrent.com" target="_blank">
        www.payrent.com
      </Button>
    </StyledLogo>
  );
}
export default Logo;
