import React, { useRef } from 'react'
import { useReactToPrint } from 'react-to-print';
import RoiCalculator from '../containers/RoiCalculator';

export default function RoiCalculatorApp() {
  const ref = useRef();
  const handlePrint = useReactToPrint({
    content: () => ref.current,
    documentTitle: 'ROI Calculator'
  });
  return <RoiCalculator ref={ref} handlePrint={handlePrint} />;
};
