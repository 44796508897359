import {
  EmailShareButton,
  LinkedinShareButton,
  FacebookShareButton,
} from 'react-share';
import { EmailIcon, LinkedinIcon, FacebookIcon } from 'react-share';

const windowLayout = {
  windowWidth: 640,
  windowHeight: 768,
};
export const LinkedinShare = (props) => {
  const { hidePopover } = props;
  return (
    <LinkedinShareButton
      url={window.location.href}
      beforeOnClick={hidePopover}
      {...windowLayout}
    >
      <LinkedinIcon size={24} round={true}></LinkedinIcon>
    </LinkedinShareButton>
  );
};
export const FacebookShare = (props) => {
  const { hidePopover } = props;
  return (
    <FacebookShareButton
      url={window.location.href}
      beforeOnClick={hidePopover}
      {...windowLayout}
    >
      <FacebookIcon size={24} round={true}></FacebookIcon>
    </FacebookShareButton>
  );
};

export const EmailShare = (props) => {
  const { hidePopover } = props;
  return (
    <EmailShareButton
      url={window.location.href}
      beforeOnClick={hidePopover}
      subject="Check out this calculator to prorate rent"
      body="I found this calculater at www.payrent.com. Thought you might be able to use it:"
      {...windowLayout}
    >
      <EmailIcon size={24} round={true}></EmailIcon>
    </EmailShareButton>
  );
};
