import React, {useContext} from 'react';
import {ErrorBlock} from 'antd-mobile';
import {DocumentCreatorContext} from '../../context/DocumentCreatorContext';
import Card from '../uielements/Card/Card';
import PromptForm from '../documentForms/PromptForm';
import DocumentDropdown from '../DocumentDropdown/DocumentDropdown';

export default function DetailsTab(props) {
  const [state] = useContext(DocumentCreatorContext);
  // console.log('[DetailsTab.js]', props, state);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch'
      }}
    >
      <DocumentDropdown />
      <div className="card-wrapper" style={{flex: '1', marginTop: '12px'}}>
        <Card title="Customize">
          {state.selectedKey ? (
            <PromptForm
              formSchema={state.selectedDocument}
              setActiveTab={props.setActiveTab}
            />
          ) : (
            <ErrorBlock
              status="empty"
              title="No Document Selected"
              description={
                <span>
                  Please select a document from the
                  <br />
                  Select Document dropdown menu
                </span>
              }
            />
          )}
        </Card>
      </div>
    </div>
  );
}
