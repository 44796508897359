import BestValue from '../../assets/bestValue.svg';
const BestValueBanner = (
  <div
    style={{
      zIndex: '999',
      position: 'absolute',
      top: -16,
      left: '50%',
      transform: 'translate(-50%,0%)',
    }}
  >
    <img
      className="bestValue"
      src={BestValue}
      alt="Best Value"
      style={{ width: '120px' }}
    />
  </div>
);

export default BestValueBanner;
