import {getApp, initializeApp} from 'firebase/app';
import {getAuth, connectAuthEmulator} from 'firebase/auth';
import {getFirestore, connectFirestoreEmulator} from 'firebase/firestore';
import {getFunctions, connectFunctionsEmulator} from 'firebase/functions';
import {getStorage, connectStorageEmulator} from 'firebase/storage';
import {getPerformance} from 'firebase/performance';
import {initializeAppCheck, ReCaptchaV3Provider} from 'firebase/app-check';
import {getAnalytics} from 'firebase/analytics';

const {
  REACT_APP_APPCHECK_DEBUG_TOKEN,
  REACT_APP_FIREBASE_CONFIG_API_KEY,
  REACT_APP_FIREBASE_CONFIG_AUTHDOMAIN,
  REACT_APP_FIREBASE_CONFIG_PROJECTID,
  REACT_APP_FIREBASE_CONFIG_BUCKET,
  REACT_APP_FIREBASE_CONFIG_SENDERID,
  REACT_APP_FIREBASE_CONFIG_APPID,
  REACT_APP_GA_MEASUREMENT_ID
} = process.env;

const firebaseConfig = {
  apiKey: REACT_APP_FIREBASE_CONFIG_API_KEY,
  authDomain: REACT_APP_FIREBASE_CONFIG_AUTHDOMAIN,
  projectId: REACT_APP_FIREBASE_CONFIG_PROJECTID,
  storageBucket: REACT_APP_FIREBASE_CONFIG_BUCKET,
  messagingSenderId: REACT_APP_FIREBASE_CONFIG_SENDERID,
  appId: REACT_APP_FIREBASE_CONFIG_APPID,
  measurementId: REACT_APP_GA_MEASUREMENT_ID || undefined
};

// console.log(firebaseConfig);

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth();
const db = getFirestore();
const storage = getStorage();
const functions = getFunctions(getApp());
const perf = getPerformance(firebaseApp);
const analytics = getAnalytics(firebaseApp);

// console.log(process.env);
if (process.env.NODE_ENV === 'development') {
  console.info('using firebase emulators', process.env);
  // eslint-disable-next-line no-restricted-globals
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = REACT_APP_APPCHECK_DEBUG_TOKEN;
  connectFirestoreEmulator(db, 'localhost', 8080);
  connectStorageEmulator(storage, 'localhost', 9199);
  connectAuthEmulator(auth, 'http://localhost:9099');
  connectFunctionsEmulator(functions, 'localhost', 5001);
}

const appCheck = initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaV3Provider(process.env.REACT_APP_APPCHECK_KEY),
  isTokenAutoRefreshEnabled: true
});

export {
  firebaseConfig,
  firebaseApp,
  auth,
  db,
  storage,
  perf,
  functions,
  appCheck,
  analytics
};
