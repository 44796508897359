import React, { useRef } from 'react'
import { useReactToPrint } from 'react-to-print';
import MoveOutCalculator from '../containers/MoveOutCalculator';

export default function MoveOutCalculatorApp() {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Move-In Calculator'
  });
  return <MoveOutCalculator ref={componentRef} handlePrint={handlePrint} />;
};
