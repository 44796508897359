import axios from 'axios';

const verifyEmail = async (email) => {
  try {
    const results = await axios.get(
      `https://ipqualityscore.com/api/json/email/b4BAvax2NPExyNuXZRIzgjghyJSDORyh/${email}`
    );
    //console.log(results);
    return results;
  } catch (err) {
    if (err.response) {
      console.error(err);
      return err.response;
    } else if (err.request) {
      // client never received a response, or request never left
      //console.log(err.request);
      return err.request;
    } else console.error(err);
  }
};
export default verifyEmail;
