import {Card, Result} from 'antd';
import React from 'react';
import {motion} from 'framer-motion';
import {StyledCalculatorButton} from '../../App.styles';

const cardVariants = {
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      type: 'spring',
      bounce: 1,
      stiffness: 200
    }
  },
  hidden: {
    opacity: 0,
    scale: 0
  }
};

export default function CustomPricingCard() {
  return (
    <motion.div variants={cardVariants}>
      <Card>
        <Result
          status="success"
          title="Good News, You Qualify for Special Pricing!"
          subTitle="We have enterprise pricing and custom integrations designed just for your business."
          extra={
            <StyledCalculatorButton
              onClick={() =>
                window.parent.Calendly.initPopupWidget({
                  url: 'https://calendly.com/mdartayet-payrent/30min'
                })
              }
            >
              SCHEDULE A PRICING ANALYSIS
            </StyledCalculatorButton>
          }
        />
      </Card>
    </motion.div>
  );
}
