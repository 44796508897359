import {Form, Input, Button, Checkbox, message} from 'antd';
import React, {useContext, useState, useEffect} from 'react';
import {
  sendMail,
  upsertContact
} from '../../library/firebase/firebase.functions';
import {DocumentCreatorContext} from '../../context/DocumentCreatorContext';
import PhoneInput from '../PhoneInput/PhoneInput';
import {
  addDocument,
  getDocument,
  putDocument
} from '../../library/firebase/firebase.firestore';
import {sanitzePhoneNumber} from '../../library/helpers/sanitizePhoneNumber';
import {generatePdf} from '../../library/helpers/generatePdf';
import blobToBase64 from '../../library/helpers/blobToBase64';
import {serverTimestamp} from 'firebase/firestore';
import useSigninSignout from '../../hooks/useSigninSignout';
import {isMobile} from 'react-device-detect';
import {logEvent} from 'firebase/analytics';
import {analytics} from '../../library/firebase/firebase';

export default function SendForm(props) {
  const {setActiveTab} = props;
  const [state, dispatch] = useContext(DocumentCreatorContext);
  const {login} = useSigninSignout();
  const [loading, setLoading] = useState(false);
  const [sendDisabled, setSendDisabled] = useState(true);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [mobileDisabled, setMobileDisabled] = useState(true);
  const [allowSend, setAllowSend] = useState(false);
  const [form] = Form.useForm();

  const handleDownload = async (e) => {
    // console.log('ƒ handleDownload', e);
    e.preventDefault();
    // window.dataLayer.push({
    //   event: 'docgpt_download',
    //   eventProps: {
    //     category: 'Button',
    //     label: 'docgpt_download',
    //     action: 'download',
    //     value: 0
    //   }
    // });
    logEvent(analytics, 'docgpt_download', {
      category: 'Button',
      label: 'docgpt_download',
      action: 'download',
      value: 0
    });

    if (state.document) {
      const newPdf = await generatePdf(state.document);
      // console.log(newPdf);
      if (!(newPdf instanceof Blob)) return message.error('Sumting Wong');
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(newPdf);
      link.download = `${state.selectedDocument.metadata.label}.pdf`;
      link.click();
    } else {
      message.error('No document to download');
    }
  };

  const handleValuesChange = (values, allValues) => {
    if (values.hasOwnProperty('notify')) {
      setMobileDisabled(!values.notify);
      !values.notify && form.setFieldValue('mobileNumber', '');
    }
    values.hasOwnProperty('accept') && setAcceptTerms(values.accept);
    values.hasOwnProperty('allow') && setAllowSend(values.allow);
  };

  async function handleFinish(values) {
    // console.log('ƒ handleFinish', values);
    // window.dataLayer.push({
    //   event: 'docgpt_send',
    //   eventProps: {
    //     category: 'Button',
    //     label: 'docgpt_send',
    //     action: 'send',
    //     value: 0
    //   }
    // });
    logEvent(analytics, 'docgpt_send', {
      category: 'Button',
      label: 'docgpt_send',
      action: 'send',
      value: 0
    });

    setLoading(true);
    try {
      const user = await getDocument('users', state.user.uid);
      // console.log('user', user);
      let documentId;

      const documentData = {
        documentHtml: state.document,
        documentMetadata: state.selectedDocument,
        recipientName: values.recipientName,
        recipientEmail: values.sendTo,
        notification: {
          returnReceipt: !!values.notify,
          smsRecipient: values.mobileNumber
            ? sanitzePhoneNumber(values.mobileNumber)
            : '',
          notificationSent: false
        }
      };

      if (!user) {
        const userData = {
          email: values.replyTo,
          fullName: values.senderName,
          createdAt: serverTimestamp()
        };
        await putDocument('users', state.user.uid, userData).then(async () => {
          documentId = await addDocument(
            `/users/${state.user.uid}/documents`,
            documentData
          ).then((res) => res.id);
          return;
        });
      } else {
        documentId = await addDocument(
          `/users/${state.user.uid}/documents`,
          documentData
        ).then((res) => res.id);
      }

      const newPdf = await generatePdf(state.document);
      const base64Pdf = await blobToBase64(newPdf);
      const {metadata} = state.selectedDocument;

      var regexp = /(^\w+)\s+(.*$)/g;
      const recipientFirst =
        Array.from(values.recipientName.matchAll(regexp), (m) => m[1])[0] || '';
      const recipientLast =
        Array.from(values.recipientName.matchAll(regexp), (m) => m[2])[0] || '';
      const senderFirst =
        Array.from(values.senderName.matchAll(regexp), (m) => m[1])[0] || '';
      const senderLast =
        Array.from(values.senderName.matchAll(regexp), (m) => m[2])[0] || '';

      const msg = {
        sender: {
          name: `${values.senderName} via PayRent`,
          email: 'no-reply@payrent.com'
        },
        to: [
          {
            email: values.sendTo,
            name: values.recipientName
          }
        ],
        replyTo: {
          email: values.replyTo,
          name: values.senderName
        },
        params: {
          TOPIC: metadata.label,
          FROMNAME: values.senderName,
          FNAME: recipientFirst,
          LNAME: recipientLast,
          FULLNAME: values.recipientName
        },
        attachment: [
          {
            content: base64Pdf,
            name: `${metadata.label}.pdf`
          }
        ],
        tags: [`${state.user.uid}.${documentId}`, 'docgpt'],
        templateId: 16
      };
      // console.log(msg);

      const recipientParams = {
        email: values.sendTo,
        attributes: {
          FIRSTNAME: recipientFirst,
          LASTNAME: recipientLast,
          SOURCE: 'Document Creator'
        },
        listIds: [16]
      };

      const senderParams = {
        email: values.replyTo,
        attributes: {
          FIRSTNAME: senderFirst,
          LASTNAME: senderLast,
          ROLE: metadata.category,
          SOURCE: 'Document Creator'
        },
        listIds: [15]
      };

      await sendMail(msg);
      await Promise.allSettled([
        upsertContact(recipientParams),
        upsertContact(senderParams)
      ]);

      message.success('Email sent successfully');
      dispatch({
        type: 'SET_AUTH',
        payload: {user: {authenticated: false, token: null}}
      });
      form.resetFields();
      setAllowSend(false);
      setAcceptTerms(false);
      setMobileDisabled(true);
      if (isMobile) {
        setActiveTab('create');
      }
      // console.log('message sent', result);
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  }

  useEffect(() => {
    // console.log('ƒ useEffect on state and form', state, acceptTerms);
    if (
      state.selectedKey &&
      state.user.authenticated &&
      acceptTerms &&
      allowSend
    ) {
      return setSendDisabled(false);
    }

    if (state.user.email) {
      form.setFieldValue('replyTo', state.user.email);
    }
    return setSendDisabled(true);
  }, [state, acceptTerms, allowSend, form]);

  return (
    <>
      <p>
        Send your completed document via email or{' '}
        <a href="http://#" onClick={(e) => handleDownload(e)}>
          <strong>download the document now to your device.</strong>
        </a>
      </p>
      <Form
        form={form}
        name="send-form"
        requiredMark={false}
        onFinish={handleFinish}
        size="default"
        onValuesChange={handleValuesChange}
      >
        <Form.Item
          name="senderName"
          label="Your Name"
          rules={[{required: true, message: 'Full name required'}]}
        >
          <Input placeholder="Jerry Seinfeld" />
        </Form.Item>
        <Form.Item
          hasFeedback
          name="replyTo"
          label="Your Email"
          rules={[
            {required: true, message: 'Email address required'},
            {type: 'email', message: 'Valid email address required'}
          ]}
        >
          <Input
            placeholder="sender@example.com"
            readOnly={state.user.authenticated}
          />
        </Form.Item>
        <Form.Item>
          <Button
            block
            disabled={state.user.authenticated}
            type="primary"
            onClick={() => login()}
          >
            Please Verify Your Email
          </Button>
        </Form.Item>
        <Form.Item
          name="recipientName"
          label="Recipient Name"
          rules={[{required: true, message: 'Recipient name required'}]}
        >
          <Input
            placeholder="Cosmo Kramer"
            disabled={!state.user.authenticated}
          />
        </Form.Item>
        <Form.Item
          name="sendTo"
          label="Recipient Email"
          hasFeedback
          rules={[
            {required: true, message: 'Email address required'},
            {type: 'email', message: 'Valid email address required'}
          ]}
        >
          <Input
            placeholder="recipient@example.com"
            disabled={!state.user.authenticated}
          />
        </Form.Item>

        <Form.Item name="notify" valuePropName="checked">
          <Checkbox disabled={!state.user.authenticated}>
            Text me to confirm receipt
          </Checkbox>
        </Form.Item>
        <Form.Item
          name="mobileNumber"
          label="Mobile Number"
          tooltip="SMS messaging rates may apply"
          rules={[
            {required: !mobileDisabled, message: 'Mobile Number is required'}
          ]}
        >
          <PhoneInput disabled={mobileDisabled || !state.user.authenticated} />
        </Form.Item>
        <Form.Item name="accept" valuePropName="checked">
          <Checkbox disabled={!state.user.authenticated}>
            I have read and accept PayRent's{' '}
            <a
              disabled={!state.user.authenticated}
              href="https://www.payrent.com/terms-conditions-2022/"
              rel="noreferrer"
              target="__blank"
            >
              terms of service and privacy policy
            </a>
          </Checkbox>
        </Form.Item>
        <Form.Item name="allow" valuePropName="checked">
          <Checkbox disabled={!state.user.authenticated}>
            I confirm the recipient has explicitly agreed to receive emails from
            me.
          </Checkbox>
        </Form.Item>
        <Button
          htmlType="submit"
          name="send-form"
          type="primary"
          size="large"
          disabled={sendDisabled}
          loading={loading}
          block
        >
          Send
        </Button>
      </Form>
    </>
  );
}
