import React, {useReducer, useEffect} from 'react';
import reducer from './reducer';
// import {createActions} from './actions';

const getInitialUserState = () => {
  const user = sessionStorage.getItem('user');
  return user ? JSON.parse(user) : {authenticated: false};
};

const initialState = {
  selectedKey: null,
  user: getInitialUserState(),
  document: '',
  selectedDocument: null,
  documentList: [],
  promptFormData: {},
  loginModalOpen: false
};



export const DocumentCreatorContext = React.createContext();

export default function DocumentCreatorProvider({children}) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    // console.log('useEffect on DocumentContextProvider', state.user);
    sessionStorage.setItem('user', JSON.stringify(state.user));
  }, [state.user]);

  return (
    <DocumentCreatorContext.Provider value={[state, dispatch]}>
      {children}
    </DocumentCreatorContext.Provider>
  );
}
