import React from 'react';
import {Card as AntCard} from 'antd';
import {Card as AdmCard} from 'antd-mobile';
import styled from 'styled-components';
import {isMobile} from 'react-device-detect';

export default function Card({children, ...cardProps}) {
  if (isMobile) {
    return <StyledMobileCard {...cardProps}>{children}</StyledMobileCard>;
  }
  return <StyledCard {...cardProps}>{children}</StyledCard>;
}

const StyledMobileCard = styled(AdmCard)`
  min-height: 100%;

`;
const StyledCard = styled(AntCard)``;
