import { ResultWrapper } from '../components.styles';
import { StyledNumber, ErrorText } from '../components.styles';
import { Typography, Row, Col } from 'antd';
import numeral from 'numeral';
import { useSpring } from 'react-spring';
const { Text } = Typography;

const NetOperatingIncomeResult = (props) => {
  //console.log('ƒ NetOperatingIncomeResult', props);
  const { netOperatingIncome, operatingExpenseRatio } = props;
  const noiSpring = useSpring({
    from: { num: 0 },
    to: { num: netOperatingIncome },
  });
  const oerSpring = useSpring({
    from: { num: 0 },
    to: { num: operatingExpenseRatio },
  });

  return (
    <>
      <ResultWrapper>
        <Row gutter={24}>
          <Col span={12}>
            <Text style={{ color: '#122c34', fontWeight: '700' }}>
              Net Income (NOI)
            </Text>
            <br />
            {noiSpring < 0 ? (
              <ErrorText>Error. Check your settings. </ErrorText>
            ) : (
              ''
            )}
            <StyledNumber>
              {noiSpring.num.to((v) => numeral(v).format('$0,0.00'))}
            </StyledNumber>
          </Col>
          <Col span={12}>
            <Text style={{ color: '#122c34', fontWeight: '700' }}>
              OpEx Ratio (OER)
            </Text>
            <br />
            {oerSpring < 0 ? (
              <ErrorText>Error. Check your settings. </ErrorText>
            ) : (
              ''
            )}
            <StyledNumber>
              {oerSpring.num.to((v) => numeral(v).format('0.00'))}
            </StyledNumber>
          </Col>
        </Row>
      </ResultWrapper>
    </>
  );
};
export default NetOperatingIncomeResult;
