import { Select } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import daysOfMonth from './daysOfMonth';

function DaySelect(props) {
  const { handleDayChange } = props;
  return (
    <Select
      className="day-select"
      onChange={handleDayChange}
      placeholder="Select day"
      showSearch
      optionFilterProp="label"
      style={{ width: 148 }}
      options={daysOfMonth}
      suffixIcon={<DownOutlined style={{ fontSize: '14px' }} />}
    />
  );
}
export default DaySelect;
