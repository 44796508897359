import React, { useRef } from 'react'
import { useReactToPrint } from 'react-to-print';
import RentSplitCalculator from '../containers/RentSplitCalculator';

export default function RentSplitCalculatorApp() {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Rent-Split Calculator'
  });
  return <RentSplitCalculator ref={componentRef} handlePrint={handlePrint} />;
};
