const monthsOfYear = [
  { label: 'January', days: '31', key: '01', value: 'JAN' },
  { label: 'February', days: '28', key: '02', value: 'FEB' },
  { label: 'March', days: '31', key: '03', value: 'MAR' },
  { label: 'April', days: '30', key: '04', value: 'APR' },
  { label: 'May', days: '31', key: '05', value: 'MAY' },
  { label: 'June', days: '30', key: '06', value: 'JUN' },
  { label: 'July', days: '31', key: '07', value: 'JUL' },
  { label: 'August', days: '31', key: '08', value: 'AUG' },
  { label: 'September', days: '30', key: '09', value: 'SEP' },
  { label: 'October', days: '31', key: '10', value: 'OCT' },
  { label: 'November', days: '30', key: '11', value: 'NOV' },
  { label: 'December', days: '31', key: '12', value: 'DEC' },
];

export default monthsOfYear;
