import React, {useContext, useState} from 'react';
import {Divider, Form, Input, Modal, Space} from 'antd';
import {CloseCircleFilled} from '@ant-design/icons';
import EmailIcon from '../../assets/email.svg';
import {DocumentCreatorContext} from '../../context/DocumentCreatorContext';
import MagicBadge from '../MagicBadge/MagicBadge';
import MagicAuth from '../MagicAuth/MagicAuth';
import GoogleAuth from '../GoogleAuth/GoogleAuth';

export default function LoginModal({setIsReadOnly}) {
  const [state, dispatch] = useContext(DocumentCreatorContext);
  const [userEmail, setUserEmail] = useState('');

  return (
    <Modal
      open={state.loginModalOpen}
      destroyOnClose
      width={432}
      height={385.75}
      footer={null}
      style={{borderRadius: '28px'}}
      bodyStyle={{padding: '24px', textAlign: 'center'}}
      closeIcon={
        <CloseCircleFilled
          style={{fontSize: '36px', color: 'rgba(0,0,0,0.1)'}}
        />
      }
      maskStyle={{
        background: 'rgba(255,255,255,0.7)',
        backdropFilter: 'blur(4px)'
      }}
      onCancel={() => dispatch({type: 'TOGGLE_LOGIN_MODAL', payload: false})}
    >
      <img alt="Email Icon" src={EmailIcon} style={{padding: '12px'}} />
      <h2>Welcome</h2>
      <p>
        This tool is free to use. But please login or sign up to access advanced
        features.
      </p>
      <Form onValuesChange={(values) => setUserEmail(values.email)}>
        <Form.Item
          name="email"
          hasFeedback
          rules={[
            {type: 'email', message: 'Valid email required'},
            {required: true, message: 'Email address is required'}
          ]}
        >
          <Input
            size="large"
            type="email"
            placeholder="b.lightyear@pixar.com"
          />
        </Form.Item>
      </Form>
      <Space direction="vertical" size={0}>
        <MagicAuth
          size="large"
          title="Log in / Sign up"
          email={userEmail}
          setIsReadOnly={setIsReadOnly}
          style={{width: '192px'}}
        />

        <MagicBadge />
        <Divider orientation="center">
          <span style={{color: 'rgba(0,0,0,0.4)'}}>or</span>
        </Divider>
        <GoogleAuth />
      </Space>
    </Modal>
  );
}
