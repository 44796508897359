import {db, storage} from './firebase';
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  setDoc,
  doc,
  serverTimestamp,
  addDoc,
  updateDoc,
  deleteDoc
} from 'firebase/firestore';

export function convertCollectionsSnapshotToMap(snapshots) {
  return snapshots.docs.reduce((accumulator, collection) => {
    accumulator[collection.id] = collection.data();
    return accumulator;
  }, {});
}

export async function addDocument(collectionName, documentData) {
  return await addDoc(collection(db, collectionName), {
    ...documentData,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp()
  });
}

export async function getDocument(collectionName, docRef) {
  const docSnap = await getDoc(doc(db, collectionName, docRef));
  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return;
  }
}

export async function getDocuments(collectionName) {
  const querySnapshot = await getDocs(collection(db, collectionName));
  return querySnapshot.docs.map((doc) => ({id: doc.id, ...doc.data()}));
}

export async function getDocumentsByQuery(collectionName, qry) {
  console.log(...qry, collectionName);

  const q = query(collection(db, collectionName), where(...qry));

  return await getDocs(q).then((querySnapshot) =>
    querySnapshot.docs.map((doc) => ({id: doc.id, ...doc.data()}))
  );
}

export async function putDocument(collectionName, docRef, documentData) {
  return await setDoc(
    doc(db, collectionName, docRef),
    {
      ...documentData,
      updatedAt: serverTimestamp()
    },
    {merge: true}
  );
}

export async function patchDocument(collectionName, docRef, documentData) {
  return await updateDoc(doc(db, collectionName, docRef), {
    ...documentData,
    updatedAt: serverTimestamp()
  });
}

export async function deleteDocuments(collectionName) {
  const collectionRef = db.collection(collectionName);
  var batch = db.batch();
  await collectionRef
    .get()
    .then((querySnapshot) =>
      querySnapshot.docs.map((doc) => batch.delete(collectionRef.doc(doc.id)))
    );

  return await batch.commit().then(() => {
    console.log('Batch Deletion successfully committed!');
  });
}

export async function deleteDocument(collectionName, doc) {
  return await deleteDoc(doc(db, collectionName, doc));
}

export const addCollectionAndDocuments = async (
  collectionKey,
  objectsToAdd
) => {
  const collectionRef = db.collection(collectionKey);
  const batch = db.batch();
  objectsToAdd.forEach((obj) => {
    const newDocRef = collectionRef.doc();
    batch.set(newDocRef, obj);
  });
  return await batch.commit().then(() => {
    console.log('Batch Addition successfully committed!');
  });
};

export async function deleteFile(fileRef) {
  console.log('ƒ deleteFile', fileRef);
  storage
    .ref()
    .child(fileRef)
    .delete()
    .then((res) => {
      console.log('File deleted successfully');
      return res;
    })
    .catch((err) => {
      console.error(err);
      return err;
    });
}
// const { title, items } = doc.data();
//     return {
//       routeName: encodeURI(title.toLowerCase()),
//       id: doc.id,
//       title,
//       items,
//     };
//   });
//   console.log(transformedCollection);
//   return transformedCollection.reduce((accumulator, collection) => {
//     accumulator[collection.title.toLowerCase()] = collection;
//     return accumulator;
//   }, {});
