import React from 'react';
import dayjs from 'dayjs';

export default function helloWorld(props) {
  const {
    fullName,
    streetAddress,
    city,
    state,
    postalCode,
    recipientName,
    depositAmount,
    vacateDate,
    advanceNoticeNumber,
    advanceNoticeInterval,
    replyTo
  } = props;
  return (
    <>
      <p>{fullName}</p>
      <p>{streetAddress}</p>
      <p>
        {city}, {state} {postalCode}
      </p>
      <br />
      <p>{dayjs().format('MMMM D, YYYY')}</p>
      <br />
      <p>Dear {recipientName},</p>
      <br />
      <p>
        I’m writing to inform you that I will not be renewing my lease. As noted
        in my contract, the unit will be{' '}
        <strong>vacant as of {dayjs(vacateDate).format('MMMM D, YYYY')}</strong>
        . This note should serve as my {advanceNoticeNumber}-
        {advanceNoticeInterval} notice of non-renewal. Upon move-in, a deposit
        of ${depositAmount} was provided and shall be returned upon leaving,
        provided that all property therein remains within good condition. If you
        have any questions, you can contact me at{' '}
        <a href={replyTo} rel="noreferrer" target="_blank">
          {replyTo}
        </a>
        .
      </p>
      <br />
      <p>Sincerely,</p>
      <p>{fullName}</p>
    </>
  );
}
