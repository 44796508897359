import React, {useContext, useEffect, useState} from 'react';
import {SafeArea, Footer, Tabs} from 'antd-mobile';
import dayjs from 'dayjs';
import DetailsTab from './DetailsTab';
import styled from 'styled-components';
import ReviewTab from './ReviewTab';
import SendTab from './SendTab';
import {doc} from 'firebase/firestore';
import {useFirestore, useFirebaseApp, useFirestoreDocData} from 'reactfire';
import {DocumentCreatorContext} from '../../context/DocumentCreatorContext';
import {cardVariants} from '../../animations/variants';
import {motion} from 'framer-motion';
import LoginModal from '../LoginModal/LoginModal';

export default function DocCreatorMobile(props) {
  const dispatch = useContext(DocumentCreatorContext)[1];
  const [activeTab, setActiveTab] = useState('create');
  const db = useFirestore(useFirebaseApp());
  const docRef = doc(db, 'templates', props.templateId || 'undefined');
  const {status, data: template} = useFirestoreDocData(docRef);

  useEffect(() => {
    if (status === 'success' && props.templateId && template) {
      dispatch({
        type: 'SELECT_DOCUMENT',
        payload: {
          selectedDocument: template,
          selectedKey: props.templateId
        }
      });
    }
  }, [status, props.templateId, dispatch, template]);

  return (
    <div>
      <div style={{background: '#ace0ff'}}>
        <SafeArea position="top" />
      </div>
      <LoginModal />
      <StyledTabs activeKey={activeTab} onChange={(key) => setActiveTab(key)}>
        <StyledTabs.Tab title="Create" key="create" destroyOnClose>
          <motion.div
            key="details-tab"
            variants={cardVariants}
            initial="hidden"
            animate="visible"
          >
            <DetailsTab setActiveTab={setActiveTab} />
          </motion.div>
        </StyledTabs.Tab>
        <StyledTabs.Tab title="Review" key="review" destroyOnClose>
          <motion.div
            key="review-tab"
            variants={cardVariants}
            initial="hidden"
            animate="visible"
          >
            <ReviewTab setActiveTab={setActiveTab} />
          </motion.div>
        </StyledTabs.Tab>
        <Tabs.Tab title="Finish" key="finish" destroyOnClose>
          <motion.div
            key="send-tab"
            variants={cardVariants}
            initial="hidden"
            animate="visible"
          >
            <SendTab setActiveTab={setActiveTab} />
          </motion.div>
        </Tabs.Tab>
      </StyledTabs>
      <Footer
        style={{
          padding: '12px 0'
        }}
        content={`\u00A9 ${dayjs().format(
          'YYYY'
        )} PayRent Inc. All rights reserved`}
      />
      <div style={{background: '#ffcfac'}}>
        <SafeArea position="bottom" />
      </div>
    </div>
  );
}

const StyledTabs = styled(Tabs)`
  .adm-tabs-content {
    background-color: var(--adm-color-box);
    height: calc(100vh - 106px);
    padding-bottom: 48;
    overflow: auto;
  }
`;
