import { ResultWrapper } from '../components.styles';
import { StyledNumber, ErrorText } from '../components.styles';
import { Typography } from 'antd';
import numeral from 'numeral';
import { useSpring } from 'react-spring';
const { Text } = Typography;

const ProratedRentResult = (props) => {
  const { proratedRent, title } = props;
  const numberSpring = useSpring({
    from: { num: 0 },
    to: { num: proratedRent }
  });
  return (
    <>
      <ResultWrapper>
        <Text style={{ color: '#122c34', fontWeight: '700' }}>{title}: </Text>
        <br />
        {proratedRent < 0 ? (
          <ErrorText>Error. Check your settings. </ErrorText>
        ) : (
          ''
        )}
        <StyledNumber>
          {numberSpring.num.to((v) => numeral(v).format('$0,0.00'))}
        </StyledNumber>
      </ResultWrapper>
    </>
  );
};
export default ProratedRentResult;
