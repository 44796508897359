import React from 'react';

const footerStyles = {
  row: {
    display: 'block',
    borderColor: 'transparent',
    borderWidth: '0px',
    marginTop: '120px',
    marginBottom: '0px',
    width: 'auto',
    backgroundColor: 'rgb(247, 247, 244)'
  },
  col: {
    backgroundColor: 'transparent',
    padding: '24px',
    verticalAlign: 'top'
  }
};

export default function Footer() {
  return (
    <div style={footerStyles.row} align="center" gutter={12}>
      <div style={footerStyles.col}>
        PayRent Inc.
        <br />
        2901 Bluegrass Bl. Suite 200
        <br />
        Lehi, UT 84043 Page <br />
        e: support@payrent.com t: 888-821-0020
      </div>
      <div style={footerStyles.col}>
        Learn how PayRent can help you{' '}
        <strong>never miss a month's rent</strong> at: <br />{' '}
        <a
          href="https://www.payrent.com?utm_medium=email&utm_source=document_creator"
          target="_blank"
          rel="noreferrer"
        >
          https://www.payrent.com
        </a>
      </div>
    </div>
  );
}
