import React, {useContext} from 'react';
import {renderToStaticMarkup} from 'react-dom/server';
import validator from '@rjsf/validator-ajv8';
import Form from '@rjsf/antd';
import {Col, Row} from 'antd';
import {DocumentCreatorContext} from '../../context/DocumentCreatorContext';
import {isMobile} from 'react-device-detect';

function ObjectFieldTemplate(props) {
  // console.log('ƒ custom ObjectFieldTemplate', props);
  const {properties, title, description, uiSchema} = props;
  const layout = JSON.parse(uiSchema['ui:layout']);

  return (
    <>
      <div className="ant-card-head-title">
        <h4>{title}</h4>
      </div>
      <div>{description}</div>
      {layout.map((row, i) => {
        return (
          <Row gutter={12} key={i}>
            {row.map((element) => {
              const key = Object.keys(element)[0];
              const property = properties.find((prop) => prop.name === key);
              const layoutProps = element[key];
              return (
                <Col key={key} {...layoutProps}>
                  {property.content}
                </Col>
              );
            })}
          </Row>
        );
      })}
    </>
  );
}

export default function PromptForm(props) {
  //   console.log('[PromptForm.js]', props);
  const [state, dispatch] = useContext(DocumentCreatorContext);
  const {selectedDocument} = state;
  const {formSchema, setActiveTab} = props;

  const handleSubmit = async (formData) => {
    // console.log('ƒ handleSubmit');
    const fileName = selectedDocument.metadata.template;
    const {default: template} = require(`../../templates/${fileName}`);
    const htmlDoc = renderToStaticMarkup(template(formData));
    // console.log(htmlDoc);
    dispatch({type: 'SET_DOCUMENT', payload: htmlDoc});
    if (isMobile) {
      setActiveTab('review');
    }
  };

  return (
    <Form
      name="prompt-form"
      className="ant-form-hide-required-mark"
      showErrorList={false}
      widgets={formSchema.widgets}
      schema={formSchema.schema}
      uiSchema={formSchema.uiSchema}
      validator={validator}
      templates={{ObjectFieldTemplate}}
      onSubmit={({formData}) => handleSubmit(formData)}
      formData={state.promptFormData}
      onChange={({formData}) =>
        dispatch({ type: 'SET_PROMPT_FORM_DATA', payload: formData })
      }
    />
  );
}
