import React, { useRef } from 'react'
import { useReactToPrint } from 'react-to-print';
import NetOperatingIncomeCalculator from '../containers/NetOperatingIncomeCalculator';

export default function NetOperatingIncomeCalculatorApp() {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Net Operating Income Calculator'
  });
  return (
    <NetOperatingIncomeCalculator
      ref={componentRef}
      handlePrint={handlePrint}
    />
  );
};
