import { ResultWrapper } from '../components.styles';
import { Typography } from 'antd';
import { StyledNumber } from '../components.styles';
import numeral from 'numeral';
import { useSpring } from 'react-spring';
const { Text } = Typography;

const TotalCostResult = (props) => {
  const { totalMoveIn, title } = props;
  const numberSpring = useSpring({
    from: { num: 0 },
    to: { num: totalMoveIn },
  });
  return (
    <ResultWrapper>
      <Text style={{ color: '#122c34', fontWeight: '700' }}>{title}: </Text>
      <br />
      <StyledNumber>
        {numberSpring.num.to((v) => numeral(v).format('$0,0.00'))}
      </StyledNumber>
    </ResultWrapper>
  );
};
export default TotalCostResult;
