import React, {useContext, useEffect} from 'react';
import {DocumentCreatorContext} from '../../context/DocumentCreatorContext';
import {signInWithGoogleIdentity} from '../../library/firebase/firebase.authentication.util';
import {setUserId, logEvent} from 'firebase/analytics';
import {analytics} from '../../library/firebase/firebase';

export default function GoogleAuth(props) {
  const dispatch = useContext(DocumentCreatorContext)[1];

  async function handleGoogleIdentityCallback(response) {
    // console.log('handleGoogleIdentityCallback' + response.credential);

    if (response.credential) {
      logEvent(analytics, 'docgpt_auth', {
        category: 'Button',
        label: 'google_auth',
        action: 'authenticate',
        value: 0,
      });

      const result = await signInWithGoogleIdentity(response.credential);
      // console.log(result);

      if (result.user) {
        const profile = {
          email: result.user.email,
          uid: result.user.uid,
          token: result.user.accessToken,
        };

        // console.log('[GoogleAuth.js] ƒ handleGoogleSignin', profile);

        setUserId(analytics, result.user.uid);

        dispatch({
          type: 'SET_AUTH',
          payload: {user: {...profile, authenticated: true}},
        });
        dispatch({type: 'TOGGLE_LOGIN_MODAL', payload: false});
      }
    }
  }

  useEffect(() => {
    /* global google */
    google.accounts.id.initialize({
      client_id: process.env.VITE_OAUTH_CLIENT_ID,
      callback: handleGoogleIdentityCallback,
    });

    google.accounts.id.renderButton(document.getElementById('google-sign-in'), {
      theme: 'outline',
      size: 'large',
    });
  }, []);

  return <div id="google-sign-in"></div>;
}
