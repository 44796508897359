import { useState, useEffect } from 'react';
import { Form, Input } from 'antd';
import { capRate } from '../../library/helpers/calculators';
import CapRateResult from '../CapRateResult/CapRateResult';

const CalculatorForm = (props) => {
  const [calculatorVals, setCalculatorVals] = useState({
    inputs: {},
    capRate: 0
  });

  useEffect(() => {
    //console.log(calculatorVals.inputs);
    const { netIncome, propertyValue } = calculatorVals.inputs;
    if (netIncome && propertyValue) {
      let calculatedCosts = capRate(calculatorVals.inputs);
      //console.log(calculatedCosts);
      setCalculatorVals((prevCalculatorVals) => {
        return {
          ...prevCalculatorVals,
          capRate: calculatedCosts.capRate
        };
      });
    }
  }, [calculatorVals.inputs]);
  return (
    <Form
      layout="vertical"
      name="capRateCalculator"
      onValuesChange={(changedValues) => {
        const key = Object.keys(changedValues)[0];
        const numericValue = parseFloat(Object.values(changedValues)[0]);
        changedValues[key] = numericValue;
        //console.log(changedValues);
        const prevCalulatorVals = calculatorVals;
        setCalculatorVals({
          ...prevCalulatorVals,
          inputs: { ...prevCalulatorVals.inputs, ...changedValues }
        });
      }}
    >
      <Form.Item label="Net Operting Income" name="netIncome">
        <Input prefix="$" />
      </Form.Item>
      <Form.Item
        label="Property Value (purchase or current)"
        name="propertyValue"
      >
        <Input prefix="$" />
      </Form.Item>

      <CapRateResult {...calculatorVals} title="Cap Rate" />
    </Form>
  );
};
export default CalculatorForm;
