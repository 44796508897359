import React, {useMemo} from 'react';
import {useLocation} from 'react-router-dom';
import {isMobile} from 'react-device-detect';
import DocCreatorDesktop from '../components/DocCreator/DocCreatorDesktop';
import DocCreatorMobile from '../components/DocCreator/DocCreatorMobile';
import DocumentCreatorProvider from '../context/DocumentCreatorContext';
import {getFirestore} from 'firebase/firestore';
import {
  FirestoreProvider,
  useFirebaseApp,
  AppCheckProvider,
  FunctionsProvider
} from 'reactfire';
import {getFunctions} from 'firebase/functions';
import { appCheck } from '../library/firebase/firebase';



export default function DocumentCreator() {
  const {search} = useLocation();
  const templateId = useMemo(
    () => new URLSearchParams(search).get('template_id'),
    [search]
  );

  const firestoreInstance = getFirestore(useFirebaseApp());
  const functions = getFunctions(useFirebaseApp());

  return (
    <DocumentCreatorProvider>
      <AppCheckProvider sdk={appCheck}>
        <FirestoreProvider sdk={firestoreInstance}>
          <FunctionsProvider sdk={functions}>
            {isMobile ? (
              <DocCreatorMobile templateId={templateId} />
            ) : (
              <DocCreatorDesktop templateId={templateId} />
            )}
          </FunctionsProvider>
        </FirestoreProvider>
      </AppCheckProvider>
    </DocumentCreatorProvider>
  );
}
