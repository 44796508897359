const servicePlans = [
  {
    name: 'pay-as-you-go',
    subscriptionFee: 0.0,
    properties: -1,
    unitPrice: 0.0,
    achRate: 0.0,
    achFee: 5.0,
    creditCardRate: 0.035,
    creditCardFee: 2.95,
  },
  {
    name: 'do-it-yourself',
    subscriptionFee: 19.0,
    properties: 25,
    unitPrice: 3.0,
    achRate: 0.0,
    achFee: 2.0,
    creditCardRate: 0.035,
    creditCardFee: 2.95,
  },
  {
    name: 'go-like-a-pro',
    subscriptionFee: 49.0,
    properties: 10,
    unitPrice: 2.0,
    achRate: 0.0,
    achFee: 0.0,
    creditCardRate: 0.035,
    creditCardFee: 2.95,
  },
];
export default servicePlans;
