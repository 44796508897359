import React, {useContext} from 'react';
import ReactQuill from 'react-quill';
import styled from 'styled-components';
import 'react-quill/dist/quill.snow.css';
// import Loader from '../Loader/Loader';

import {DocumentCreatorContext} from '../../context/DocumentCreatorContext';

export default function TextEditor(props) {
  const {readOnly} = props;
  const [state, dispatch] = useContext(DocumentCreatorContext);
  // console.log('[TextEditor.js]', props, state);

  return (
    // <Loader spinning={loading} style={{marginTop: '45px'}}>
    <StyledEditor
      theme="snow"
      readOnly={readOnly}
      value={state.document}
      preserveWhitespace
      onChange={(content) => {
        const cleanContent = content === '<p><br></p>' ? '' : content;
        return dispatch({type: 'SET_DOCUMENT', payload: cleanContent});
      }}
    />
    // {/* </Loader> */}
  );
}

const StyledEditor = styled(ReactQuill)`
  display: flex;
  height: 100%;
  flex-direction: column;

  .ql-container {
    overflow: auto;
    white-space: pre-line;
    flex: 1;
    background: ${(p) => (p.readOnly ? 'rgba(0,0,0,0.02)' : 'transparent')};
    color: ${(p) => (p.readOnly ? 'rgba(0,0,0,0.66)' : 'rgba(0,0,0,0.9)')};
    -webkit-user-select: ${(p) => (p.readOnly ? 'none' : 'auto')};
    -webkit-touch-callout: ${(p) => (p.readOnly ? 'none' : 'auto')};
    -moz-user-select: ${(p) => (p.readOnly ? 'none' : 'auto')};
    -ms-user-select: ${(p) => (p.readOnly ? 'none' : 'auto')};
    user-select: ${(p) => (p.readOnly ? 'none' : 'auto')};
  }
`;
