import { useState, useEffect } from 'react';
import { Form, Input, Space } from 'antd';
import DaySelect from '../DaySelect/DaySelect';
import MonthSelect from '../MonthSelect/MonthSelect';
import ProratedRentResult from '../ProratedRentResult/ProratedRentResult';
import { calculateMoveOutCosts } from '../../library/helpers/calculators';

const CalculatorForm = (props) => {
  const [calculatorVals, setCalculatorVals] = useState({
    proratedRent: 0
  });

  const handleDayChange = (value, option) => {
    //console.log('ƒ handleDayChange', option);
    setCalculatorVals({ ...calculatorVals, moveOutDay: value });
  };

  const handleMonthChange = (value, option) => {
    //console.log('ƒ handleMonthChange', option);
    setCalculatorVals({ ...calculatorVals, daysInMonth: option.days });
  };

  useEffect(() => {
    //console.log(calculatorVals);
    const { daysInMonth, moveOutDay, rentAmount } = calculatorVals;
    if (daysInMonth && moveOutDay && rentAmount) {
      let calculatedCosts = calculateMoveOutCosts(calculatorVals);
      //console.log(calculatedCosts);
      setCalculatorVals((prevCalculatorVals) => {
        return {
          ...prevCalculatorVals,
          proratedRent: calculatedCosts.proratedRent
        };
      });
    }
  }, [
    calculatorVals.daysInMonth,
    calculatorVals.moveOutDay,
    calculatorVals.rentAmount
  ]);
  return (
    <Form
      layout="vertical"
      name="MoveOutCalculator"
      onValuesChange={(changedValues) => {
        //console.log(changedValues);
        setCalculatorVals({ ...calculatorVals, ...changedValues });
      }}
    >
      <Form.Item label="Rent Amount" name="rentAmount">
        <Input prefix="$" />
      </Form.Item>
      <Space size={14}>
        <Form.Item label="Move-Out Month" name="daysInMonth">
          <MonthSelect handleMonthChange={handleMonthChange} />
        </Form.Item>
        <Form.Item label="Move-Out Day" name="moveOutDay">
          <DaySelect handleDayChange={handleDayChange} />
        </Form.Item>
      </Space>
      <ProratedRentResult
        {...calculatorVals}
        title="Final Month's Prorated Rent"
      />
    </Form>
  );
};
export default CalculatorForm;
