import {
  StyledPrinterOultined,
  StyledShareAltOutlined,
  StyledFileDoneOutlined
} from '../../components/components.styles';
import { Button, Space, Tooltip } from 'antd';
import SocialShare from '../../components/SocialShare/SocialShare';
import { useState } from 'react';

function PrinterButton(props) {
  return (
    <Tooltip title="Print">
      <Button
        type="text"
        icon={<StyledPrinterOultined style={props.iconStyle} />}
        onClick={props.handlePrint}
      />
    </Tooltip>
  );
}

function EsignButton(props) {
  return (
    <Tooltip title="E-Sign">
      <Button
        type="text"
        icon={<StyledFileDoneOutlined style={props.iconStyle} />}
        onClick={() => console.log('EsignButton clicked')}
      />
    </Tooltip>
  );
}

function ShareButton(props) {
  const [state, setState] = useState({ visible: false });
  const hidePopover = () => {
    setState({
      visible: false
    });
  };
  const handleVisibleChange = (visible) => {
    setState({ visible });
  };
  return (
    <>
      <SocialShare
        trigger="click"
        visible={state.visible}
        onVisibleChange={handleVisibleChange}
        placement="left"
        hidePopover={hidePopover}
      >
        <Button
          type="text"
          icon={<StyledShareAltOutlined style={props.iconStyle} />}
        />
      </SocialShare>
    </>
  );
}

export const Actions = (props) => {
  const { showAction } = props;

  return (
    <Space size={5}>
      {showAction.indexOf('esign') !== -1 ? (
        <EsignButton iconStyle={props.iconStyle} />
      ) : null}
      {showAction.indexOf('share') !== -1 ? (
        <ShareButton iconStyle={props.iconStyle} />
      ) : null}
      {showAction.indexOf('print') !== -1 ? (
        <PrinterButton
          handlePrint={props.handlePrint}
          iconStyle={props.iconStyle}
        />
      ) : null}
    </Space>
  );
};
