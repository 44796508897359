import { useState, useEffect, useRef } from 'react';
import { Form, Input, InputNumber, Space, Button, Switch } from 'antd';
import { rentSplitCalculator } from '../../library/helpers/calculators';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import RentSplitResult from '../RentSplitResult/RentSplitResult';

const CalculatorForm = (props) => {
  const initialValues = {
    rentAmount: 0,
    bedrooms: [
      {
        key: 0,
        sqFootage: 0,
        occupants: 1,
        privateBath: false,
        type: 'bedroom'
      }
    ]
  };
  const [calculatorVals, setCalculatorVals] = useState(initialValues);
  const [calculatorResults, setCalculatorResults] = useState(initialValues);
  const calculatorValsRef = useRef(calculatorVals);

  useEffect(() => {
    //console.log('ƒ useEffect', calculatorVals, calculatorValsRef);
    const { rentAmount, totalSquareFootage, bedrooms } = calculatorVals;
    if (rentAmount && totalSquareFootage && bedrooms.length) {
      calculatorValsRef.current = rentSplitCalculator(calculatorVals);
      setCalculatorResults(calculatorValsRef.current);
    }
  }, [calculatorVals]);

  return (
    <Form
      layout="vertical"
      name="RentSplitCalculator"
      requiredMark={false}
      initialValues={initialValues}
      onValuesChange={(_, allValues) => {
        //console.log(allValues);
        setCalculatorVals(allValues);
      }}
    >
      <Space size={14}>
        <Form.Item label="Rent Amount" name="rentAmount">
          <Input prefix="$" />
        </Form.Item>
        <Form.Item label="Total Sq. Footage" name="totalSquareFootage">
          <Input />
        </Form.Item>
      </Space>
      <Form.List name="bedrooms">
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => (
              <Space
                key={key}
                style={{ display: 'flex', marginBottom: 8 }}
                align="center"
              >
                <Form.Item
                  {...restField}
                  name={[name, 'sqFootage']}
                  label="Bdr. Sq. Ft."
                  rules={[{ required: true, message: 'Missing sq footage' }]}
                >
                  <InputNumber />
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, 'occupants']}
                  label="Occupants"
                  rules={[
                    { required: true, message: 'Missing no. of occupants' }
                  ]}
                >
                  <InputNumber min={1} max={10} />
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, 'privateBath']}
                  label="Bath"
                  rules={[{ required: true, message: 'Missing private bath' }]}
                  valuePropName="checked"
                >
                  <Switch
                    checkedChildren="private"
                    unCheckedChildren="shared"
                  />
                </Form.Item>
                {index !== 0 && (
                  <MinusCircleOutlined onClick={() => remove(name)} />
                )}
              </Space>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() =>
                  add({
                    key: calculatorVals.bedrooms.length,
                    sqFootage: 0,
                    occupants: 1,
                    privateBath: false,
                    type: 'bedroom'
                  })
                }
                block
                icon={<PlusOutlined />}
              >
                Add bedroom
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      <RentSplitResult title="Rent-Split Calculation" {...calculatorResults} />
    </Form>
  );
};
export default CalculatorForm;
