import React, {useContext, useState} from 'react';
import TextEditor from '../TextEditor/TextEditor';
import Card from '../uielements/Card/Card';
import {Button, Space} from 'antd-mobile';
import {CheckCircleFilled, LeftOutlined} from '@ant-design/icons';
import {DocumentCreatorContext} from '../../context/DocumentCreatorContext';
import useSigninSignout from '../../hooks/useSigninSignout';
import {logEvent} from 'firebase/analytics';
import {analytics} from '../../library/firebase/firebase';

export default function ReviewTab(props) {
  const [state] = useContext(DocumentCreatorContext);
  const {login} = useSigninSignout();
  const [editButtonLoading, setEditButtonLoading] = useState(false);

  const handleEdit = async () => {
    // window.dataLayer.push({
    //   event: 'docgpt_edit',
    //   eventProps: {
    //     category: 'Button',
    //     label: 'docgpt_edit',
    //     action: 'edit',
    //     value: 0
    //   }
    // });
    logEvent(analytics, 'docgpt_edit', {
      category: 'Button',
      label: 'docgpt_edit',
      action: 'edit',
      value: 0
    });
    setEditButtonLoading(true);
    try {
      await login();
    } catch (err) {
      console.error(err);
    }
    setEditButtonLoading(false);
  };

  return (
    <Card
      title="Review"
      extra={
        <Space style={{gap: '6px'}}>
          <Button
            fill="none"
            color="primary"
            size="small"
            onClick={() => props.setActiveTab('create')}
          >
            <LeftOutlined style={{fontSize: '1em'}} /> Back
          </Button>
          <Button
            size="small"
            loading={editButtonLoading}
            onClick={handleEdit}
            color={state.user.authenticated ? 'primary' : 'default'}
            fill={state.user.authenticated ? 'outline' : 'solid'}
            disabled={!state.document}
          >
            Edit
            {state.user.authenticated && (
              <CheckCircleFilled
                style={{color: 'rgb(82,196,26)', marginLeft: '6px'}}
              />
            )}
          </Button>
          <Button
            size="small"
            fill="solid"
            color="primary"
            onClick={() => props.setActiveTab('finish')}
          >
            Next
          </Button>
        </Space>
      }
    >
      <TextEditor readOnly={!state.user.authenticated || !state.document} />
    </Card>
  );
}
