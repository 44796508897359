import React from 'react';
import Footer from './Footer';

export default function DefaultEmail(props) {
  const {recipientName, senderName, descriptor} = props;
  return (
    <>
      <table
        width="640"
        cellspacing="0"
        cellpadding="0"
        border="0"
        align="center"
        style={{maxWidth: '600px', width: '100%'}}
      >
        <tr>
          <td align="left" valign="top" style={{padding: '10px'}}>
            <p>Hi {recipientName},</p>
            <p>
              We're sending you this email from {senderName} regarding{' '}
              {descriptor}.
            </p>
            <p>
              This document was generated using a free document creator courtesy
              of{' '}
              <a href="https://www.payrent.com?utm_medium=email&utm_source=document_creator" target='_blank' rel='noreferrer'>
                PayRent
              </a>
              .
            </p>
            <p>Sincerely,</p>
            <p>Your Friends at PayRent</p>
          </td>
        </tr>
      </table>
      <Footer />
    </>
  );
}
