import {message} from 'antd';
import {useContext} from 'react';
import {auth} from '../library/firebase/firebase';
import { signOutUser } from '../library/firebase/firebase.authentication.util';
import { checkUser, logoutUser } from '../services/magic';
import { DocumentCreatorContext } from '../context/DocumentCreatorContext';


export default function useSigninSignout() {
  const dispatch = useContext(DocumentCreatorContext)[1];

  const logout = async () => {
    try {
      await checkUser().then(async ({isLoggedIn}) => {
        isLoggedIn && (await logoutUser());
      });
      const authSignout = await signOutUser();
      if (authSignout) {
        dispatch({
          type: 'SET_AUTH',
          payload: {
            user: {authenticated: false, email: null, token: null, uid: null}
          }
        });
        message.success('Form and email verification reset');
      }
    } catch (err) {
      console.error(err);
    }
  };

  const login = async () => {
    try {
      if (!auth.currentUser) {
        dispatch({type: 'TOGGLE_LOGIN_MODAL', payload: true});
      } else {
        // console.log('[useSigninSignout.js] ƒ login', auth.currentUser);
        dispatch({
          type: 'SET_AUTH',
          payload: {
            user: {
              authenticated: true,
              uid: auth.currentUser.uid,
              email: auth.currentUser.email,
              token: auth.currentUser.accessToken
            }
          }
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  return {login, logout};
}
